import React, { useState } from "react";
import useProjectForm from '../../../hooks/Projects/useProjectForm';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import Table from '../../../Common/table/Table';
import EmployeeList from "../../employee/employee-list/employee-list";
const ProjectForm = ({ project, onFormSumbit, edit, isModal, onCloseModalClick }) => {
    const { selectedClient, clients, address, name, pricePerHour, showAlert, alertMessage, fixedPrice, employees, setFixedPrice, setName, setAddress, setPricePerHour, setSelectedClient, onFormClick, updatePricePerHour, onFormCancle } =
        useProjectForm({ project, onFormSumbit, edit, isModal, onCloseModalClick });
    if (project !== null)
        return (
            <div className="row client-form">

                <div className="col-6 form-control-container">
                    <label htmlFor="name" className="form-label form-control-label">
                        Name
                    </label>
                    <input
                        type="text"
                        className="form-control form-input"
                        id="name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </div>

                <div className="col-6 form-control-container ">
                    <label htmlFor="tva" className="form-label form-control-label">
                        Client
                    </label>
                    <Combobox
                        onChange={value => setSelectedClient(value.id)}
                        data={clients}
                        dataKey='id'
                        textField='name'
                        value={selectedClient}
                        defaultValue={selectedClient}
                        containerClassName={" form-combobox"}
                    />

                </div>
                <div className="col-6  form-control-container ">
                    <label htmlFor="address" className="form-label form-control-label">
                        Address
                    </label>
                    <textarea
                        className="form-control form-input"
                        id="address"
                        rows="3"
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                    ></textarea>
                </div>
                {fixedPrice && <div className="col-6  form-control-container ">
                    <label htmlFor="pricePerHour" className="form-label form-control-label">
                        Price per hour
                    </label>
                    <input
                        type="number"
                        className="form-control form-input"
                        id="pricePerHour"
                        value={pricePerHour}
                        onChange={(event) => setPricePerHour(event.target.value)}
                    ></input>
                </div>}

                <div className="col-12  form-control-container ">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                        checked={fixedPrice}
                        onChange={() => setFixedPrice(!fixedPrice)}
                    />
                    <label htmlFor="pricePerHour" className="form-label form-control-label">
                        Fixed Price
                    </label>

                </div>
                <div className={`collapse  ${!fixedPrice ? 'show' : ''}`}>
                    <div className="card card-body col-12 ">
                        <ul className="list-group">
                            {employees.map((e, index) => (<li className="list-group-item " >
                                <label htmlFor="pricePerHour" className="col-form-label "> {e.name}</label>

                                <input
                                    type="text"
                                    className="form-control form-input"
                                    id="pricePerHour"
                                    value={e.pricePerHour}
                                    onChange={(event) => updatePricePerHour(parseFloat(event.target.value), index)}
                                ></input>
                            </li>))}
                        </ul>
                    </div>
                </div>


                <div className="d-flex justify-content-start mt-4 mb-3">
                    <button type="submit" className="btn btn-secondary me-2" onClick={() => onFormCancle()}>
                        Cancle
                    </button>  <button type="submit" className="btn btn-primary" onClick={(e) => onFormClick(e)}>
                        {edit ? 'Edit' : 'Save'}
                    </button>
                </div>

                {
                    showAlert &&
                    <div className="col-12 mt-4 mb-3">
                        <span htmlFor="name" className="validate-error">
                            {alertMessage}
                        </span>
                    </div>
                }

            </div >
        );
};

export default ProjectForm;