import React, { Component } from "react";
import useAddClient from '../../../hooks/clients/useAddClient';
import ClientForm from "../client-form/client-form";
const AddClientModal = ({ showModal, onCloseModalClick, onSaveAndClose }) => {

  const {
    showAlert, intialClient,
    alertMessage, alertType, onCloseAlertClick, onFormClick
  } = useAddClient();
  if (showAlert && alertType === "success") {
    onSaveAndClose(alertMessage);
    onCloseAlertClick();
  }
  return (
    <div className={`modal  ${showModal ? 'show' : ''}`} tabindex="-1">
      <div className="modal-dialog  modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">New Client</h5>
          </div>
          <div className="modal-body">
            <ClientForm
              client={intialClient}
              onFormSumbit={onFormClick}
              edit={false}
              isModal={true}
              onCloseModalClick={onCloseModalClick} />
          </div>

        </div>
      </div>
    </div>
  );
}

export default AddClientModal;