import React, { useState } from "react";
import UserForm from '../../../pages/user/user-form/user-form';
import useAddUser from '../../../hooks/users/useAddUser';
import Alerts from "../../../Common/Alerts";
const AddUser = () => {

    const { initialUser, showAlert, alertMessage, alertType, onFormClick, onCloseAlertClick } = useAddUser()

    return (<div className="container">
        <div className="main-page-content">
            <h2 className="main-page-conten-title">Add Employee</h2>

            <div className="row">
                <Alerts show={showAlert} message={alertMessage} type={alertType} onCloseAlertClick={onCloseAlertClick} />
            </div>
            <UserForm user={initialUser}
                onFormSumbit={onFormClick}
                edit={false}
                isModal={false} />

        </div>
    </div>
    )



}
export default AddUser