import { useEffect, useState } from "react";
import { AddProject } from '../../api/projectApi';
import { useUserContext } from "../useUserContext";
import { GetAllClients } from '../../api/clientApi';
import { GetAllProjects } from '../../api/projectApi';
import { GetAllEmployee } from '../../api/employeeApi';
import { GetEmployeeReport, DownloadEmployeeReportPdf } from '../../api/serviceReportApi';

const useEmployeeReport = () => {

    const { state, dispatch } = useUserContext();
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [projects, setProjects] = useState([]);
    const [displayProjects, setDisplayProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [employees, setEmployees] = useState([]);
    const [displayEmployees, setDisplayEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [selectedFromDate, setSelectedFromDate] = useState(new Date());
    const [selectedToDate, setSelectedToDate] = useState(new Date());
    const [totalHours, setTotalHours] = useState(0);
    const [serviceReport, setServiceReport] = useState([]);

    const columns = [
        { name: "Client", columnNumber: 0 }
        , { name: "Date", columnNumber: 1 }
        , { name: "Project", columnNumber: 2 }
        , { name: "Team leader", columnNumber: 3 }
        , { name: "From", columnNumber: 4 }
        , { name: "To", columnNumber: 5 }
        , { name: "Break", columnNumber: 6 }
        , { name: "Total Hours", columnNumber: 7 }
        , { name: "Description", columnNumber: 8 }];

    useEffect(() => {
        const fetchClients = async () => {
            if (Object.keys(clients).length === 0) {
                await fetchAllClients();
            }
        }
        const fetchProjects = async () => {
            if (Object.keys(projects).length === 0) {
                await fetchAllProjects();
            }
        }
        const fetchEmployees = async () => {
            if (Object.keys(employees).length === 0) {
                await fetchAllEmployees();
            }
        }
        fetchProjects();
        fetchClients();
        fetchEmployees();

    }, []);
    useEffect(() => {
        console.log("selectedToDate: " + selectedToDate);
        console.log("selectedFromDate: " + selectedFromDate);

    }, [selectedToDate]);
    const getHourDifference = (timeString1, timeString2, takeBreak) => {
        // Convert time strings to Date objects
        const date1 = new Date(`2000-01-01T${timeString1}`);
        const date2 = new Date(`2000-01-01T${timeString2}`);

        // Calculate the difference in milliseconds between the two dates
        const timeDifference = Math.abs(date2 - date1);

        // Convert milliseconds to hours
        const hoursDifference = timeDifference / (1000 * 60 * 60); // 1 hour = 1000 * 60 * 60 milliseconds

        return takeBreak ? hoursDifference - 0.5 : hoursDifference;
    }

    useEffect(() => {

        const getEmployeeReport = async () => {
            if (Object.keys(projects).length != 0) {
                var employeeReport = [];
                var result = await GetEmployeeReport(selectedEmployee, selectedClient, selectedProject, selectedFromDate, selectedToDate, state.token);
                if (result.length > 0) {

                    employeeReport = result.map(item => ({
                        client: item.client,
                        date: item.workDate,
                        project: item.project,
                        teamleader: item.teamLeader,
                        from: item.startTime,
                        to: item.endTime,
                        break: item.takeBreak ? 'YES' : 'NO',
                        hours: getHourDifference(item.startTime, item.endTime),
                        description: `${item.tasks.length != 0 ? `Tasks:${item.tasks}` : ``}   ${item.notes.length != 0 ? `\n Note:  ${item.notes}` : ``} `
                    }));
                }
                console.log(employeeReport);
                var sumeTotalHours = 0;
                employeeReport.map(item => (sumeTotalHours = sumeTotalHours + item.hours));
                setTotalHours(sumeTotalHours);
                setServiceReport(employeeReport);
            }
        }
        getEmployeeReport();
    }, [selectedEmployee, selectedClient, selectedProject, selectedFromDate, selectedToDate]);

    useEffect(() => {
        if (Object.keys(projects).length != 0 && selectedClient != "") {

            let newprojecList = (selectedClient == "0" ? projects : projects.filter(item => (item.clientId.toLocaleLowerCase() == selectedClient.toLocaleLowerCase()))).map(item => ({
                id: item.id,
                name: item.name,
            }));
            var displayProjects = [{
                id: "0",
                name: "All",
            }, ...newprojecList];
            var index = displayProjects.findIndex(item => item.id == selectedProject);
            setSelectedProject(index > 0 ? displayProjects[index].id : displayProjects[0].id)
            setDisplayProjects(displayProjects);
        }
    }, [selectedClient]);

    useEffect(() => {
        if (Object.keys(clients).length != 0 && selectedProject != "" && selectedProject != "0") {
            let clientId = projects.filter(item => item.id == selectedProject)[0].clientId.toLocaleLowerCase();
            setSelectedClient(clientId);
        }
    }, [selectedProject]);

    const fetchAllEmployees = async () => {
        var response = await GetAllEmployee(state.token);
        let employees = await response.map(item => ({
            id: item.id,
            name: item.firstName + '' + item.lastName,
        }));
        setSelectedEmployee(employees[0].id)
        setDisplayEmployees(employees);
        setEmployees(response);
    }

    const fetchAllProjects = async () => {
        var response = await GetAllProjects(state.token);
        let projects = await response.map(item => ({
            id: item.id,
            name: item.name,
        }));
        var displayProjects = [{
            id: "0",
            name: "All",
        }, ...projects]

        setSelectedProject(displayProjects[0].id)
        setDisplayProjects(displayProjects);
        setProjects(response);
    }
    const fetchAllClients = async () => {
        var response = await GetAllClients(state.token);
        let clients = await response.map(item => ({
            id: item.id,
            name: item.name
        }));
        var displayClients = [{
            id: '0',
            name: "All"
        }, ...clients];
        setClients(displayClients);
        setSelectedClient(displayClients[0].id);
    }

    return {
        clients, columns, selectedClient, displayProjects, selectedProject, selectedFromDate, selectedToDate, serviceReport, totalHours,
        selectedEmployee, displayEmployees,
        setSelectedEmployee, setSelectedClient, setSelectedProject, setSelectedFromDate, setSelectedToDate,
        onDownloadPdf: async () => {
            console.log("DownloadEmployeeReportPdf");
            const response = await DownloadEmployeeReportPdf(selectedEmployee, selectedClient, selectedProject, selectedFromDate, selectedToDate, state.token);


            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileUrl = URL.createObjectURL(file);
            // Create a temporary anchor tag with the file URL and download attribute
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', 'kop.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }
    }

}
export default useEmployeeReport;