import React, { useReducer } from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './pages/login/login';
import Home from './pages/home/home';
import EmployeeList from './pages/employee/employee-list/employee-list';
import AddEmployee from './pages/employee/add-employee/add-employee';
import EditEmployee from './pages/employee/edit-employee/edit-employee';

import AddClient from './pages/client/add-client/add-client';
import ClientList from './pages/client/client-List/client-list';
import EditClient from './pages/client/edit-client/edit-client';
import './assets/font/font.css';
import PrivateRoutes from './PrivateRoutes';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './providers/userProvider';
import { userReducer } from './reducers/userReducer';
import AddProject from './pages/projects/add-project/add-project';
import ProjectList from './pages/projects/project-list/project-list';
import EditProject from './pages/projects/edit-Project/edit-project';

import ServiceReport from './pages/report/service-report/service-report';
import EmployeeReport from './pages/report/employee-report/employee-report';


import AddUser from './pages/user/add-user/add-user';
import UserList from './pages/user/user-list/user-list';
import EditUser from './pages/user/edit-user/edit-user';

const initialState = {
  user: null,
  token: null
};
const App = () => {

  const state = useReducer(userReducer, initialState);
  return (<div>
    <Router>
      <UserProvider value={state}>
        <Routes>
          <Route element={<PrivateRoutes />}>

            <Route element={<Home />} path="/" exact>
              <Route element={<EmployeeList />} path="/employee-list" />
              <Route element={<AddEmployee />} path="/add-employee" />
              <Route element={<EditEmployee />} path="/edit-employee/:id" />

              <Route element={<AddClient />} path="/add-client" />
              <Route element={<EditClient />} path="/edit-client/:id" />
              <Route element={<ClientList />} path="/all-clients" />
              <Route element={<ProjectList />} path="/all-projects" />
              <Route element={<AddProject />} path="/add-project" />
              <Route element={<EditProject />} path="/edit-project/:id" />


              <Route element={<EmployeeReport />} path="/employee-report" />
              <Route element={<ServiceReport />} path="/service-report" />

              <Route element={<AddUser />} path="/add-user" />
              <Route element={<UserList />} path="/all-users" />
              <Route element={<EditUser />} path="/edit-user/:id" />


            </Route>
          </Route>
          <Route element={<Login />} path="/login" />
        </Routes></UserProvider>
    </Router>
  </div>

  );
}

export default App;
