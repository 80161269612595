import { useState, useEffect } from "react";
import { GetAllProjects, DeleteProject } from '../../api/projectApi';
import { useUserContext } from "../useUserContext";
import { useNavigate } from 'react-router-dom';


const useProjectList = () => {
    const [projects, setProjects] = useState([]);
    const [displayProjects, setDisplayProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteText, setDeleteText] = useState('');
    const [message, setMessage] = useState('');
    const [idForDelete, setIdForDelete] = useState('');

    const [error, setError] = useState(null);
    const { state, dispatch } = useUserContext();
    const history = useNavigate();
    useEffect(() => {

        const fetchProjects = async () => {
            if (Object.keys(projects).length === 0) {
                await fetchAllProjects();
            }
        }
        fetchProjects();

    }, []);
    const fetchAllProjects = async () => {
        var response = await GetAllProjects(state.token);
        let displayProjects = await response.map(item => ({
            name: item.name,
            client: item.client,
            address: item.address,
            pricePerHour: item.pricePerHour
        }));
        console.log(displayProjects);
        setDisplayProjects(displayProjects);
        setProjects(response);
        setIsLoading(false);
        setError(null);
    }

    return {
        isLoading,
        displayProjects,
        showModal,
        message,
        showDeleteModal,
        deleteText,
        onRefreshClick: () => {
            fetchAllProjects();
        },
        onSaveAndClose: (message) => {
            setShowModal(false);
            setMessage(message);
            fetchAllProjects();
            setTimeout(() => {
                setMessage('');
            }, 4000);
        },
        onShowModalClick: () => {
            setShowModal(true);
        },
        onCloseModalClick: () => {
            setShowModal(false);
        },
        handleEdit: (project) => {
            let id = projects.find(x => x.name == project.name && x.address == project.address).id;
            history(`/edit-project/${id}`);
        },
        handleDelete: (project) => {
            let id = projects.find(x => x.name == project.name && x.address == project.address).id;
            setShowDeleteModal(true);
            setIdForDelete(id);
            setDeleteText(" name:" + project.name + "\n"
                + " address: " + project.address + "\n"
                + " client: " + project.client + "\n");
        },
        onClosDeleteModal: () => {
            setDeleteText('');
            setShowDeleteModal(false);
        },
        onDeleteClick: async () => {
            var response = await DeleteProject(idForDelete, state.token);
            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setDeleteText('');
                setShowDeleteModal(false);
                setMessage("Project deleted successfully!");
                fetchAllProjects();
                setTimeout(() => {
                    setMessage('');
                }, 4000);

            } else {
                setDeleteText(" Project is not deleted!!! ");
            }

        }
    }


}
export default useProjectList;