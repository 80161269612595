
import React from "react";
import Agent from "./agent.jsx";

export const GetServiceReport = async (selectedClient, selectedProject, selectedFromDate, selectedToDate, token) => {
    const { requests } = Agent();
    console.log(selectedClient + ' ' + selectedProject + ' ' + selectedFromDate + ' ' + selectedToDate);
    var attribute = {
        SelectedClient: selectedClient,
        SelectedProject: selectedProject,
        SelectedFromDate: selectedFromDate,
        SelectedToDate: selectedToDate
    };

    return await requests.getAtt('/Reports/GetServiceReport', attribute, token);
}
export const GetEmployeeReport = async (selectedEmployee, selectedClient, selectedProject, selectedFromDate, selectedToDate, token) => {
    const { requests } = Agent();
    console.log(selectedEmployee + '' + selectedClient + ' ' + selectedProject + ' ' + selectedFromDate + ' ' + selectedToDate);
    var attribute = {
        SelectedEmployee: selectedEmployee,
        SelectedClient: selectedClient,
        SelectedProject: selectedProject,
        SelectedFromDate: selectedFromDate,
        SelectedToDate: selectedToDate
    };

    return await requests.getAtt('/Reports/GetEmployeeReport', attribute, token);
}
export const DownloadServiceReportPdf = async (selectedClient, selectedProject, selectedFromDate, selectedToDate, token) => {
    const { requests } = Agent();
    console.log(selectedClient + ' ' + selectedProject + ' ' + selectedFromDate + ' ' + selectedToDate);
    var attribute = {
        SelectedClient: selectedClient,
        SelectedProject: selectedProject,
        SelectedFromDate: new Date(selectedFromDate),
        SelectedToDate: new Date(selectedToDate)
    };

    return await requests.getFile('/Reports/DownloadServiceReportPdf', attribute, token);
}
export const DownloadEmployeeReportPdf = async (selectedEmployee, selectedClient, selectedProject, selectedFromDate, selectedToDate, token) => {
    const { requests } = Agent();
    console.log(selectedEmployee + '' + selectedClient + ' ' + selectedProject + ' ' + selectedFromDate + ' ' + selectedToDate);
    var attribute = {
        SelectedEmployee: selectedEmployee,
        SelectedClient: selectedClient,
        SelectedProject: selectedProject,
        SelectedFromDate: new Date(selectedFromDate),
        SelectedToDate: new Date(selectedToDate)
    };

    return await requests.getFile('/Reports/DownloadEmployeeReportPdf', attribute, token);
}
