import React, { Component } from "react";
import useServiceReport from '../../../hooks/service/useServiceReport';
import FilterTable from '../../../Common/filterTable/FilterTable';

const ServiceReport = () => {


    const { clients, columns, selectedClient, displayProjects, selectedProject, selectedFromDate, selectedToDate, serviceReport, totalHours,
        setSelectedClient, setSelectedProject, setSelectedFromDate, setSelectedToDate, onDownloadPdf } = useServiceReport();

    if (false) {
        return <div>Loading...</div>;
    } else {
        if (true) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h2>Service Report</h2>
                            <FilterTable
                                title={"Filter"}
                                columns={columns}
                                combo1={{ name: 'Client', visibility: true, data: clients }}
                                combo2={{ name: 'Project', visibility: true, data: displayProjects }}
                                combo3={{ name: '', visibility: false, data: clients }}
                                data={serviceReport}
                                handleDate1Change={setSelectedFromDate}
                                selectedDate1={selectedFromDate}
                                selectedDate2={selectedToDate}
                                handleDate2Change={setSelectedToDate}
                                selectedCombo1={selectedClient}
                                selectedCombo2={selectedProject}
                                selectedCombo3={''}
                                handleCombo1Change={setSelectedClient}
                                handleCombo2Change={setSelectedProject}
                                handleCombo3Change={() => { }}

                                onfirstButtonClick={onDownloadPdf}

                            />
                        </div>

                        <div>{totalHours > 0 ? `Total Hours:${totalHours}` : ''}</div>

                    </div>
                </div>
            );
        }
    }
}
export default ServiceReport;