import { useState, useEffect } from "react";
import { EditProject, GetProject } from '../../api/projectApi';
import { useUserContext } from "../useUserContext";

import { useParams } from 'react-router-dom';

const useEditProject = () => {
    const { state, dispatch } = useUserContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [clearForm, setClearForm] = useState(false);
    const [project, setProject] = useState({});
    const [editProject, setEditProject] = useState({});

    const [isValid, setIsValid] = useState(false); // add isValid state variable
    const { id } = useParams();


    const validateForm = () => { };
    const getProjectDetails = async (id) => {
        let projectData = await GetProject(id, state.token);
        const editProject = {
            name: projectData.name,
            client: projectData.clientId,
            address: projectData.address,
            pricePerHour: projectData.pricePerHour,
            fixedPrice: projectData.fixedPrice,
            employees: projectData.employeeSalaryPerProject
        };
        console.log(projectData);
        setProject(projectData);
        setEditProject(editProject);
    }
    useEffect(() => {
        const fetchProject = async () => {
            if (Object.keys(project).length === 0) {
                await getProjectDetails(id);
            }
        }
        fetchProject();

    }, [id]);
    return {
        showAlert, alertMessage, alertType, clearForm, editProject,
        onFormClick: async (project) => {
            project['Id'] = id;
            var response = await EditProject(project, state.token);

            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setShowAlert(true);
                setClearForm(true);
                setAlertMessage("Project saved successfully!");
                setAlertType("success");
                return true;

            } else {
                setShowAlert(true);
                setClearForm(false);
                setAlertMessage("Project is not saved");
                setAlertType("danger");
                return false;

            }

        },
        onCloseAlertClick: () => {
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
        }
    }


}
export default useEditProject;