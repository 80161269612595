const Alerts = ({ show, message, type, onCloseAlertClick }) => {
    if (show) {
        return (<div className="col-12">
            <div className={`alert alert-${type} ${show ? 'show' : ''} alert-dismissible`} role="alert">
                {message}
                <div></div>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => onCloseAlertClick()}></button>
            </div>
        </div>
        );
    }


}
export default Alerts;