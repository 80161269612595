import { useState, useEffect } from "react";
import { GetAllClients, DeleteClient } from '../../api/clientApi';
import { useUserContext } from "../useUserContext";
import { useNavigate } from 'react-router-dom';


const useClientList = () => {
    const [clients, setClients] = useState([]);
    const [displayClients, setDisplayClients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteText, setDeleteText] = useState('');
    const [message, setMessage] = useState('');
    const [idForDelete, setIdForDelete] = useState('');

    const [error, setError] = useState(null);
    const { state, dispatch } = useUserContext();
    const history = useNavigate();
    useEffect(() => {

        const fetchClients = async () => {
            if (Object.keys(clients).length === 0) {
                await fetchAllClients();
            }
        }
        fetchClients();

    }, []);
    const fetchAllClients = async () => {
        var response = await GetAllClients(state.token);
        let displayClients = await response.map(item => ({
            name: item.name,
            tva: item.tvaNumber,
            contactPerson: item.contactPerson,
            email: item.email,
            mobile: item.mobile,
            address: item.address,
            backAccount: item.bankAccount,
        }));
        setDisplayClients(displayClients);
        setClients(response);
        setIsLoading(false);
        setError(null);
    }

    return {
        isLoading,
        displayClients,
        showModal,
        message,
        showDeleteModal,
        deleteText,
        onRefreshClick: () => {
            fetchAllClients();
        },
        onSaveAndClose: (message) => {
            setShowModal(false);
            setMessage(message);
            fetchAllClients();
            setTimeout(() => {
                setMessage('');
            }, 4000);
        },
        onShowModalClick: () => {
            setShowModal(true);
        },
        onCloseModalClick: () => {
            setShowModal(false);
        },
        handleEdit: (client) => {
            let id = clients.find(x => x.name == client.name && x.tvaNumber == client.tva).id;
            history(`/edit-client/${id}`);
        },
        handleDelete: (client) => {
            let id = clients.find(x => x.name == client.name && x.tvaNumber == client.tva).id;
            setShowDeleteModal(true);
            setIdForDelete(id);
            setDeleteText(" name:" + client.name + "\n"
                + " tva: " + client.tvaNumber + "\n"
                + " contactPerson: " + client.contactPerson + "\n"
                + " email: " + client.email + "\n"
                + " mobile: " + client.mobile + "\n"
                + "address: " + client.address + "\n"
                + "backAccount: " + client.bankAccount + "\n");
        },
        onClosDeleteModal: () => {
            setDeleteText('');
            setShowDeleteModal(false);
        },
        onDeleteClick: async () => {
            var response = await DeleteClient(idForDelete, state.token);
            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setDeleteText('');
                setShowDeleteModal(false);
                setMessage("Client deleted successfully!");
                fetchAllClients();
                setTimeout(() => {
                    setMessage('');
                }, 4000);

            } else {
                setDeleteText(" Client is not deleted!!! ");
            }

        }
    }


}
export default useClientList;