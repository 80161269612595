import React from "react";
import useEditProject from '../../../hooks/Projects/useEditProject';
import Alerts from "../../../Common/Alerts";
import ProjectForm from '../project-form/project-form';
const EditProject = () => {

    const {
        showAlert, alertMessage, alertType, clearForm, editProject, onFormClick, onCloseAlertClick
    } = useEditProject();

    if (editProject !== null && Object.keys(editProject).length !== 0) {
        return (
            <div className="container">
                <div className="main-page-content">
                    <h2 className="main-page-conten-title">Add Project</h2>

                    <div className="row">
                        <Alerts show={showAlert} message={alertMessage} type={alertType} onCloseAlertClick={onCloseAlertClick} />

                    </div>
                    <ProjectForm
                        project={editProject}
                        onFormSumbit={onFormClick}
                        edit={true}
                        isModal={false} />

                </div>
            </div>

        );
    }
};

export default EditProject;