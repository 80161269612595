import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import NavSideBar from "../../Common/NavSideBar";
import Logo from '../../assets/images/SB-logo.png';
import './home.css';
const Home = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-2 header-pana">
                    <div className="sidebare-header">
                        <div className="sidebar-logo d-flex">
                            <img className="logo-img p-2" src={Logo} />
                            <h3 className="p-2 flex-grow-1 ">SB Bouwerken</h3>
                        </div>
                    </div>

                </div>
                <div className="col-10">
                    user name
                </div>
            </div>
            <div className="row">
                <div className="col-xl-2 navbar-panal">
                    <NavSideBar />
                </div>
                <div className="col-xl-10 col-lg-10 col-md-10 col-12 main-page">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
export default Home;