import ProjectForm from "../project-form/project-form";
import useAddProject from "../../../hooks/Projects/useAddProject";
import Alerts from "../../../Common/Alerts";
const AddProject = () => {
    const { showAlert, alertMessage, alertType, intialProject, onFormClick
        , onCloseAlertClick } = useAddProject()
    return (
        <div className="container">
            <div className="main-page-content">
                <h2 className="main-page-conten-title">Add Project</h2>

                <div className="row">
                    <Alerts show={showAlert} message={alertMessage} type={alertType} onCloseAlertClick={onCloseAlertClick} />

                </div>
                <ProjectForm
                    project={intialProject}
                    onFormSumbit={onFormClick}
                    edit={false}
                    isModal={false} />

            </div>
        </div>
    )
}
export default AddProject;