import Agent from "./agent.jsx";
export const GetAlluserRoles = async (token) => {
    const { requests } = Agent();
    return await requests.get('/user/GetAllUserRoles', token);
}
export const AddUser = async (user, token) => {
    const { requests } = Agent();
    return await requests.post('/User/AddUser', user, token);
}
export const GetAllUsers = async (token) => {
    const { requests } = Agent();
    return await requests.get('/User/GetAllUsers', token);
}
export const GetUser = async (id, token) => {
    const { requests } = Agent();
    return await requests.get(`/User/${id}`, token);
}
export const EditUser = async (user, token) => {
    const { requests } = Agent();
    return await requests.put('/User/editUser', user, token);
}
