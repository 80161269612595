import React from "react";
import useEditEmployee from '../../../hooks/employees/useEditEmployee';
import Alerts from "../../../Common/Alerts";
import EmployeeForm from "../employee-form/employee-form";

const EditEmployee = () => {

    const {
        showAlert, alertMessage, alertType, editEmployee, onFormClick, onCloseAlertClick
    } = useEditEmployee();

    if (editEmployee !== null && Object.keys(editEmployee).length !== 0) {
        return (
            <div className="container">
                <div className="main-page-content">
                    <h2 className="main-page-conten-title">Add Client</h2>

                    <div className="row">
                        <Alerts show={showAlert} message={alertMessage} type={alertType} onCloseAlertClick={onCloseAlertClick} />

                    </div>
                    <EmployeeForm employee={editEmployee}
                        onFormSumbit={onFormClick}
                        edit={true}
                        isModal={false} />

                </div>
            </div>

        );
    }
};

export default EditEmployee;