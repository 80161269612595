import React, { useState } from "react";
import UserForm from '../../../pages/user/user-form/user-form';
import useAddUser from '../../../hooks/users/useAddUser';
import Alerts from "../../../Common/Alerts";
const AddUserModal = ({ showModal, onCloseModalClick, onSaveAndClose }) => {

    const {
        showAlert, initialUser,
        alertMessage, alertType, onCloseAlertClick, onFormClick
    } = useAddUser();
    if (showAlert && alertType === "success") {
        onSaveAndClose(alertMessage);
        onCloseAlertClick();
    }
    return (
        <div className={`modal  ${showModal ? 'show' : ''}`} tabindex="-1">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">New Project</h5>
                    </div>
                    <div className="modal-body">
                        <UserForm
                            user={initialUser}
                            onFormSumbit={onFormClick}
                            edit={false}
                            isModal={true}
                            onCloseModalClick={onCloseModalClick} />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AddUserModal;