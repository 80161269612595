import React, { useState } from "react";
import UserForm from '../../../pages/user/user-form/user-form';
import useEditUser from '../../../hooks/users/useEditUser';
import Alerts from "../../../Common/Alerts";
const EditUser = () => {

    const { editUser, showAlert, alertMessage, alertType, onFormClick, onCloseAlertClick } = useEditUser()
    if (editUser !== null && Object.keys(editUser).length !== 0) {
        return (<div className="container">
            <div className="main-page-content">
                <h2 className="main-page-conten-title">Add Employee</h2>

                <div className="row">
                    <Alerts show={showAlert} message={alertMessage} type={alertType} onCloseAlertClick={onCloseAlertClick} />
                </div>
                <UserForm user={editUser}
                    onFormSumbit={onFormClick}
                    edit={true}
                    isModal={false} />

            </div>
        </div>
        )



    }
}
export default EditUser