import { useEffect, useState } from "react";
import { AddProject } from '../../api/projectApi';
import { useUserContext } from "../useUserContext";
import { GetAllClients } from '../../api/clientApi';
import { GetAllProjects } from '../../api/projectApi';
import { GetServiceReport, DownloadServiceReportPdf } from '../../api/serviceReportApi';

const useServiceReport = () => {

    const { state, dispatch } = useUserContext();
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [projects, setProjects] = useState([]);
    const [displayProjects, setDisplayProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedFromDate, setSelectedFromDate] = useState(new Date());
    const [selectedToDate, setSelectedToDate] = useState(new Date());
    const [totalHours, setTotalHours] = useState(0);
    const [serviceReport, setServiceReport] = useState([]);

    const columns = [
        { name: "Client", columnNumber: 0 }
        , { name: "Date", columnNumber: 1 }
        , { name: "Project", columnNumber: 2 }
        , { name: "Number of Employee", columnNumber: 3 }
        , { name: "From", columnNumber: 4 }
        , { name: "To", columnNumber: 5 }
        , { name: "Hours", columnNumber: 6 }
        , { name: "Total Hours", columnNumber: 7 }
        , { name: "Description", columnNumber: 8 }];

    useEffect(() => {
        const fetchClients = async () => {
            if (Object.keys(clients).length === 0) {
                await fetchAllClients();
            }
        }
        const fetchProjects = async () => {
            if (Object.keys(projects).length === 0) {
                await fetchAllProjects();
            }
        }
        fetchProjects();
        fetchClients();

    }, []);
    useEffect(() => {
        console.log("selectedToDate: " + selectedToDate);
        console.log("selectedFromDate: " + selectedFromDate);

    }, [selectedToDate]);
    const getHourDifference = (timeString1, timeString2, takebreak) => {
        // Convert time strings to Date objects
        const date1 = new Date(`2000-01-01T${timeString1}`);
        const date2 = new Date(`2000-01-01T${timeString2}`);

        // Calculate the difference in milliseconds between the two dates
        const timeDifference = Math.abs(date2 - date1);

        // Convert milliseconds to hours
        const hoursDifference = timeDifference / (1000 * 60 * 60); // 1 hour = 1000 * 60 * 60 milliseconds
        console.log(takebreak);
        return takebreak ? hoursDifference - 0.5 : hoursDifference;
    }

    useEffect(() => {

        const getserviceReport = async () => {
            if (Object.keys(projects).length != 0) {
                var serviceReport = [];
                var result = await GetServiceReport(selectedClient, selectedProject, selectedFromDate, selectedToDate, state.token);
                if (result.length > 0) {
                    let newArray = [];
                    let index = -1;

                    result.map(item => {
                        index = newArray.findIndex(sr => sr.workDate == item.workDate && sr.project == item.project && sr.startTime == item.startTime && sr.endTime == item.endTime);
                        if (index > -1) {
                            newArray[index].employees.push(item.employee.firstName + " " + item.employee.lastName);
                            newArray[index].notes.push(item.notes);
                        } else {
                            newArray[newArray.length] = {
                                client: item.client,
                                project: item.project,
                                workDate: item.workDate,
                                startTime: item.startTime,
                                endTime: item.endTime,
                                employees: [item.employee.firstName + " " + item.employee.lastName],
                                hours: getHourDifference(item.startTime, item.endTime, item.takeBreak),
                                TotalHours: '',
                                notes: [item.notes],

                            }
                        }
                    })
                    if (newArray.length > 0) {
                        serviceReport = newArray.map(item => ({
                            client: item.client,
                            date: item.workDate,
                            project: item.project,
                            employees: item.employees.length,
                            from: item.startTime,
                            to: item.endTime,
                            hours: item.hours,
                            totalHours: Number(item.employees.length * item.hours),
                            description: `Employee:${item.employees.join(", ")} \n Note:  ${item.notes.map(n => n).join('\n')}`
                        }));
                        console.log(serviceReport);


                    }
                }
                var sumeTotalHours = 0
                serviceReport.map(item => (sumeTotalHours = sumeTotalHours + item.totalHours));
                setTotalHours(sumeTotalHours);

                setServiceReport(serviceReport);
            }
        }
        getserviceReport();
    }, [selectedClient, selectedProject, selectedFromDate, selectedToDate]);

    useEffect(() => {
        if (Object.keys(projects).length != 0 && selectedClient != "") {

            let newprojecList = (selectedClient == "0" ? projects : projects.filter(item => (item.clientId.toLocaleLowerCase() == selectedClient.toLocaleLowerCase()))).map(item => ({
                id: item.id,
                name: item.name,
            }));
            var displayProjects = [{
                id: "0",
                name: "All",
            }, ...newprojecList];
            setSelectedProject(displayProjects[0].id)
            setDisplayProjects(displayProjects);
        }
    }, [selectedClient]);

    const fetchAllProjects = async () => {
        var response = await GetAllProjects(state.token);
        let projects = await response.map(item => ({
            id: item.id,
            name: item.name,
        }));
        var displayProjects = [{
            id: "0",
            name: "All",
        }, ...projects]

        setSelectedProject(displayProjects[0].id)
        setDisplayProjects(displayProjects);
        setProjects(response);
    }
    const fetchAllClients = async () => {
        var response = await GetAllClients(state.token);
        let clients = await response.map(item => ({
            id: item.id,
            name: item.name
        }));
        var displayClients = [{
            id: '0',
            name: "All"
        }, ...clients];
        setClients(displayClients);
        setSelectedClient(displayClients[0].id);
    }
    return {
        clients, columns, selectedClient, displayProjects, selectedProject, selectedFromDate, selectedToDate, serviceReport, totalHours,
        setSelectedClient, setSelectedProject, setSelectedFromDate, setSelectedToDate,
        onDownloadPdf: async () => {
            console.log("serviceReport");
            let reportName = '';
            let reportType = '';
            const response = await DownloadServiceReportPdf(selectedClient, selectedProject, selectedFromDate, selectedToDate, state.token);
            if (response.data.type.includes('pdf')) {
                reportType = 'application/pdf';
                if (selectedProject != 0) {
                    reportName = (displayProjects.find(item => item.id == selectedProject).name || 'Project') + '.pdf';
                } else if (selectedClient != 0) {
                    reportName = (clients.find(client => client.id == selectedClient).name || 'All projects') + '.pdf';
                } else {
                    reportName = 'All projects.pdf';
                }


            } else {
                reportName = 'All Customers.zip';
                reportType = 'application/zip'
            }

            const file = new Blob([response.data], { type: reportType });
            const fileUrl = URL.createObjectURL(file);
            // Create a temporary anchor tag with the file URL and download attribute
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', reportName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }
    }

}
export default useServiceReport;