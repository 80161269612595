import React, { useState, useEffect } from "react";
import axios from "axios";
import useClientList from '../../../hooks/clients/useClientList';
import Table from "../../../Common/table/Table";
import AddClientModal from "./add-client-modal";
import DeleteModal from "../../../Common/DeleteModal";

import './client-list.css';
const ClientList = () => {
    const columns = [{ name: "Name", columnNumber: 0 }, { name: "TVA", columnNumber: 1 }, { name: "Contact Person", columnNumber: 2 }, { name: "Email", columnNumber: 3 }, { name: "Mobile", columnNumber: 4 }, { name: "Address", columnNumber: 5 }, { name: "Bank Account", columnNumber: 6 }];
    const { displayClients, isLoading, showModal, message, showDeleteModal, deleteText, onSaveAndClose,
        onRefreshClick, onShowModalClick, onClosDeleteModal, onDeleteClick, onCloseModalClick, handleEdit, handleDelete } = useClientList();

    if (isLoading) {
        return <div>Loading...</div>;
    } else {
        if (displayClients) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <DeleteModal showModal={showDeleteModal} text={deleteText} title={"Client"} onCloseModalClick={onClosDeleteModal} onDeleteClick={onDeleteClick} />
                        <AddClientModal
                            showModal={showModal}
                            onSaveAndClose={onSaveAndClose}
                            onCloseModalClick={onCloseModalClick}

                        />
                        {displayClients.length > 0 ? (
                            <div className="col-12">
                                <h2>Client List</h2>

                                <Table
                                    onRefreshClick={onRefreshClick}
                                    message={message}
                                    onShowModalClick={onShowModalClick}
                                    columns={columns}
                                    data={displayClients}
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                />
                            </div>
                        ) : (
                            <div>No clients found.</div>
                        )}
                    </div></div>
            );
        }
    }
}

export default ClientList;