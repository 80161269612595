import React, { useState } from "react";
import useUserForm from '../../../hooks/users/useUserForm';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import { useRouteError } from "react-router-dom";
const UserForm = ({ user, onFormSumbit, edit, isModal, onCloseModalClick }) => {
    const { selectedUserRole, selectedEmployee, email, username, userRoles, employees, showAlert, alertMessage, setEmail, setUsername, setSelectedUserRole, setSelectedEmployee, onFormClick, onFormCancle } =
        useUserForm({ user, onFormSumbit, edit, isModal, onCloseModalClick });
    if (user !== null)
        return (
            <div className="row client-form">
                <div className="col-6 form-control-container ">
                    <label htmlFor="Employee" className="form-label form-control-label">
                        Employee
                    </label>
                    <Combobox
                        onChange={value => setSelectedEmployee(value.id)}
                        data={employees}
                        dataKey='id'
                        textField='name'
                        value={selectedEmployee}
                        defaultValue={selectedEmployee}
                        containerClassName={" form-combobox"}
                    />
                </div>
                <div className="col-6 form-control-container">
                    <label htmlFor="name" className="form-label form-control-label">
                        Username:
                    </label>
                    <input
                        type="Username"
                        className="form-control form-input"
                        id="username"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                    />
                </div>
                <div className="col-6 form-control-container">
                    <label htmlFor="name" className="form-label form-control-label">
                        Email:
                    </label>
                    <input
                        type="Email"
                        className="form-control form-input"
                        id="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>


                <div className="col-6 form-control-container ">
                    <label htmlFor="UserRole" className="form-label form-control-label">
                        User Role
                    </label>
                    <Combobox
                        onChange={value => setSelectedUserRole(value.name)}
                        data={userRoles}
                        dataKey='id'
                        textField='name'
                        value={selectedUserRole}
                        defaultValue={selectedUserRole}
                        containerClassName={" form-combobox"}
                    />
                </div>




                <div className="d-flex justify-content-start mt-4 mb-3">
                    <button type="submit" className="btn btn-secondary me-2" onClick={() => onFormCancle()}>
                        Cancle
                    </button>  <button type="submit" className="btn btn-primary" onClick={(e) => onFormClick(e)}>
                        {edit ? 'Edit' : 'Save'}
                    </button>
                </div>

                {
                    showAlert &&
                    <div className="col-12 mt-4 mb-3">
                        <span htmlFor="name" className="validate-error">
                            {alertMessage}
                        </span>
                    </div>
                }

            </div>
        );
};

export default UserForm;