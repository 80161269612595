import React from "react";
import { useState } from "react";
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import DatePicker from "react-widgets/DatePicker";


import EditIcon from '../../assets/icons/edit-icon.png';
import DeleteIcon from '../../assets/icons/delete-icon.png';
import plusIcon from '../../assets/icons/plus-icon.png';
import refreshIcon from '../../assets/icons/refresh-icon.png';

import '../table/Table.css';
function FilterTable({ columns, data, title, handleDate1Change, handleDate2Change, selectedDate1, selectedDate2, combo1, combo2, combo3,
    handleCombo1Change, handleCombo2Change, handleCombo3Change, selectedCombo1, selectedCombo2, selectedCombo3, onfirstButtonClick }) {
    const [sortColumn, setSortColumn] = useState("");
    const [filterText, setFilterText] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");

    const handleSort = (column) => {
        if (column === sortColumn) {
            // Reverse the sort direction if the same column is clicked again
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            // Set the new sort column and default sort direction
            setSortColumn(column);
            setSortDirection("asc");
        }
    };
    const sortedData =
        [...data].filter(item => {
            return Object.values(item).some(value =>
                value.toString().toLowerCase().includes(filterText)
            );
        })
            .sort((a, b) => {
                if (sortDirection === "asc") {
                    return a[Object.keys(a)[sortColumn.columnNumber]] > b[Object.keys(b)[sortColumn.columnNumber]] ? 1 : -1;
                } else {
                    return a[Object.keys(a)[sortColumn.columnNumber]] < b[Object.keys(b)[sortColumn.columnNumber]] ? 1 : -1;
                }
            });
    const onInputChangehandle = (event) => {
        setFilterText(event.target.value.toLowerCase());
    }
    return (

        <div className="container-fluid ">
            <div className="row">
                <div className=" filter-panel h-50 pt-2 d-flex align-items-center justify-content-between">
                    <div className="col-auto me-2">
                        <h4 className="col-form-label">{title}</h4>
                    </div>
                    {combo1.visibility && <div className="col-auto form-control-container me-3">
                        <label htmlFor="" className="form-label">
                            {combo1.name}
                        </label>
                        <Combobox
                            onChange={value => handleCombo1Change(value.id)}
                            dataKey='id'
                            textField='name'
                            data={combo1.data}
                            value={selectedCombo1}
                            defaultValue={selectedCombo1}
                            containerClassName={" form-combobox"}
                        />

                    </div>}
                    {combo2.visibility && <div className="col-auto form-control-container me-3">
                        <label className="form-label">
                            {combo2.name}
                        </label>
                        <Combobox
                            onChange={value => handleCombo2Change(value.id)}
                            dataKey='id'
                            textField='name'
                            data={combo2.data}
                            value={selectedCombo2}
                            defaultValue={selectedCombo2}
                            containerClassName={" form-combobox"}
                        />

                    </div>}
                    {combo3.visibility && <div className="col-auto form-control-container me-3">
                        <label className="form-label">
                            {combo3.name}
                        </label>
                        <Combobox
                            onChange={value => handleCombo3Change(value.id)}
                            dataKey='id'
                            textField='name'
                            data={combo3.data}
                            value={selectedCombo3}
                            defaultValue={selectedCombo3}
                            containerClassName={" form-combobox"}
                        />

                    </div>}
                    <div className="col-auto form-control-container me-3">

                        <label htmlFor="" className="form-label">
                            From Date
                        </label>
                        <DatePicker
                            containerClassName={" form-combobox"}
                            defaultValue={selectedDate1}
                            valueEditFormat={{ dateStyle: "short" }}
                            valueDisplayFormat={{ dateStyle: "medium" }}
                            onChange={value => handleDate1Change(value)}
                        /></div>
                    <div className="col-auto form-control-container ">

                        <label htmlFor="" className="form-label">
                            To Date
                        </label>
                        <DatePicker
                            containerClassName={" form-combobox"}
                            defaultValue={selectedDate2}
                            valueEditFormat={{ dateStyle: "short" }}
                            valueDisplayFormat={{ dateStyle: "medium" }}
                            onChange={value => handleDate2Change(value)}
                        /></div>


                    <div className="col-auto ms-auto">
                        <button className="btn btn-primary  me-3" onClick={onfirstButtonClick}>
                            <img src={plusIcon} alt="" />
                        </button>
                        <button className="btn btn-primary" >
                            <img src={refreshIcon} alt="" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="row">

                    <div className="scrollable-table-container">


                        <table className="table table-componenet">
                            <thead>
                                <tr>
                                    {columns.map((column) => (
                                        <th className="table-header text-nowrap" key={column.name} onClick={() => handleSort(column)}>
                                            {column.name}
                                            {sortColumn === column.name && (
                                                <span className={`arrow ${sortDirection === "asc" ? "asc" : "desc"}`}></span>
                                            )}
                                        </th>
                                    ))}

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sortedData.length === 0 ? (
                                        <div className="mb-3 ms-2">No Result</div>) : (
                                        sortedData.map((row, index) => (
                                            <tr key={index}>
                                                {columns.map((column) => (
                                                    <td className={`table-cell ${column.columnNumber == (columns.length - 1) ? 'text-wrap' : 'text-nowrap'}`} key={column.name}>{row[Object.keys(sortedData[index])[column.columnNumber]]}</td>
                                                ))}

                                            </tr>
                                        )))}
                            </tbody>
                        </table> </div>   </div>

            </div>
        </div>
    );

}

export default FilterTable;
