import { useState, useEffect } from "react";
import { EditClient, GetClient } from '../../api/clientApi';
import { useUserContext } from "../useUserContext";

import { useParams } from 'react-router-dom';

const useEditClient = () => {
    const { state, dispatch } = useUserContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [clearForm, setClearForm] = useState(false);
    const [client, setClient] = useState({});
    const [editClient, setEditClient] = useState({});

    const [isValid, setIsValid] = useState(false); // add isValid state variable
    const { id } = useParams();


    const validateForm = () => { };
    const getClientDetails = async (id) => {
        let clientData = await GetClient(id, state.token);
        const editClient = {
            name: clientData.name,
            tva: clientData.tvaNumber,
            contactPerson: clientData.contactPerson,
            mobile: clientData.mobile,
            email: clientData.email,
            address: clientData.address,
            bankAccount: clientData.bankAccount

        };
        setClient(clientData);
        setEditClient(editClient);

    }
    useEffect(() => {
        const fetchClient = async () => {
            if (Object.keys(client).length === 0) {
                await getClientDetails(id);
            }
        }
        fetchClient();
    }, [id]);
    return {
        showAlert, alertMessage, alertType, clearForm, editClient,
        onFormClick: async (client) => {
            client['Id'] = id;
            var response = await EditClient(client, state.token);
            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setShowAlert(true);
                setClearForm(true);
                setAlertMessage("Client saved successfully!");
                setAlertType("success");
                return true;



            } else {
                setShowAlert(true);
                setClearForm(false);
                setAlertMessage("Client is not saved");
                setAlertType("danger");
                return false;

            }

        },
        onCloseAlertClick: () => {
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
        }
    }


}
export default useEditClient;