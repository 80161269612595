import { useState, useEffect } from "react";
import { GetAllUsers } from '../../api/userApi';
import { DeleteProject } from '../../api/projectApi';
import { useUserContext } from "../useUserContext";
import { useNavigate } from 'react-router-dom';


const useUserList = () => {
    const [users, setUsers] = useState([]);
    const [displayUsers, setDisplayUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteText, setDeleteText] = useState('');
    const [message, setMessage] = useState('');
    const [idForDelete, setIdForDelete] = useState('');

    const [error, setError] = useState(null);
    const { state, dispatch } = useUserContext();
    const history = useNavigate();
    useEffect(() => {

        const fetchUsers = async () => {
            if (Object.keys(users).length === 0) {
                await fetchAllUsers();
            }
        }
        fetchUsers();

    }, []);
    const fetchAllUsers = async () => {
        var response = await GetAllUsers(state.token);
        let displayUsers = await response.map(item => ({
            email: item.email,
            employee: item.employee,
            userRole: item.userRole,
        }));
        setDisplayUsers(displayUsers);
        setUsers(response);
        setIsLoading(false);
        setError(null);
    }

    return {
        isLoading,
        displayUsers,
        showModal,
        message,
        showDeleteModal,
        deleteText,
        onRefreshClick: () => {
            fetchAllUsers();
        },
        onSaveAndClose: (message) => {
            setShowModal(false);
            setMessage(message);
            fetchAllUsers();
            setTimeout(() => {
                setMessage('');
            }, 4000);
        },
        onShowModalClick: () => {
            setShowModal(true);
        },
        onCloseModalClick: () => {
            setShowModal(false);
        },
        handleEdit: (user) => {
            let id = users.find(x => x.email == user.email).id;
            history(`/edit-user/${id}`);
        },
        handleDelete: (user) => {
            let id = users.find(x => x.email == user.email).id;
            setShowDeleteModal(true);
            setIdForDelete(id);
            setDeleteText(" user:" + user.Email + "\n");
        },
        onClosDeleteModal: () => {
            setDeleteText('');
            setShowDeleteModal(false);
        },
        onDeleteClick: async () => {
            var response = await DeleteProject(idForDelete, state.token);
            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setDeleteText('');
                setShowDeleteModal(false);
                setMessage("User deleted successfully!");
                fetchAllUsers();
                setTimeout(() => {
                    setMessage('');
                }, 4000);

            } else {
                setDeleteText(" User is not deleted!!! ");
            }

        }
    }


}
export default useUserList;