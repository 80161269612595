import { useState, useEffect } from "react";
import { EditEmployee, GetEmployee } from '../../api/employeeApi';
import { useUserContext } from "../useUserContext";

import { useParams } from 'react-router-dom';

const useEditEmployee = () => {
    const { state, dispatch } = useUserContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [clearForm, setClearForm] = useState(false);
    const [employee, setEmployee] = useState({});
    const [editEmployee, setEditEmployee] = useState({});

    const [isValid, setIsValid] = useState(false); // add isValid state variable
    const { id } = useParams();


    const validateForm = () => { };

    const getEmployeeDetails = async (id) => {
        let employeeData = await GetEmployee(id, state.token);
        const editemployee = {
            alias: employeeData.alias,
            firstName: employeeData.firstName,
            lastName: employeeData.lastName,
            dateOfBirth: employeeData.dateOfBirth,
            address: employeeData.address,
            mobile: employeeData.mobile,
            email: employeeData.email,
            startDate: employeeData.startDate,
            endDate: employeeData.endDate,
            pricePerHour: employeeData.pricePerHour,
            nationality: employeeData.nationality,
            nationalNumber: employeeData.nationalNumber,
            nationalAddress: employeeData.nationalAddress,
            jobTitle: employeeData.jobTitleId,

        };
        setEmployee(employeeData);
        setEditEmployee(editemployee);

    }
    useEffect(() => {
        const fetchClient = async () => {
            if (Object.keys(employee).length === 0) {
                await getEmployeeDetails(id);
            }
        }
        fetchClient();

    }, [id]);
    return {
        showAlert, alertMessage, alertType, clearForm, editEmployee,
        onFormClick: async (employee) => {
            employee['Id'] = id;
            var response = await EditEmployee(employee, state.token);
            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setShowAlert(true);
                setClearForm(true);
                setAlertMessage("Employee saved successfully!");
                setAlertType("success");
                return true;



            } else {
                setShowAlert(true);
                setClearForm(false);
                setAlertMessage("Employee is not saved");
                setAlertType("danger");
                return false;

            }

        },
        onCloseAlertClick: () => {
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
        }
    }


}
export default useEditEmployee;