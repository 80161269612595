import { Outlet, Navigate } from 'react-router-dom'
import React from 'react';
import { useUserContext } from "./hooks/useUserContext";
const PrivateRoutes = () => {
    let auth = { 'token': false }
    const { state, dispatch } = useUserContext();
    return (
        state.token != null && state.user != null ? <Outlet /> : <Navigate to="/login" />
    )
}

export default PrivateRoutes