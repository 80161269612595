import React, { useState } from "react";
import useClientForm from '../../../hooks/clients/useClientForm';

import './client-form.css';
const ClientForm = ({ client, onFormSumbit, edit, isModal, onCloseModalClick }) => {
    const { bankAccount, address, email, mobile, contactPerson, name, tva, showAlert, alertMessage, setName, setTva,
        setContactPerson, setMobile, setEmail, setAddress, setBankAccount, onFormClick, onFormCancle } =
        useClientForm({ client, onFormSumbit, edit, isModal, onCloseModalClick });
    if (client !== null)
        return (
            <div className="row client-form">

                <div className="col-6 form-control-container">
                    <label htmlFor="name" className="form-label form-control-label">
                        Name
                    </label>
                    <input
                        type="text"
                        className="form-control form-input"
                        id="name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </div>

                <div className="col-6 form-control-container ">
                    <label htmlFor="tva" className="form-label form-control-label">
                        TVA
                    </label>
                    <input
                        type="text"
                        className="form-control form-input"
                        id="tva"
                        value={tva}
                        onChange={(event) => setTva(event.target.value)}
                    />
                </div>

                <div className="col-6 form-control-container ">
                    <label htmlFor="contactPerson" className="form-label form-control-label">
                        Contact Person
                    </label>
                    <input
                        type="text"
                        className="form-control form-input"
                        id="contactPerson"
                        value={contactPerson}
                        onChange={(event) => setContactPerson(event.target.value)}
                    />
                </div>

                <div className="col-6 form-control-container">
                    <label htmlFor="mobile" className="form-label form-control-label">
                        Mobile
                    </label>
                    <input
                        type="number"
                        className="form-control form-input"
                        id="mobile"
                        value={mobile}
                        onChange={(event) => setMobile(event.target.value)}
                    />
                </div>

                <div className="col-6 form-control-container">
                    <label htmlFor="email" className="form-label form-control-label">
                        Email
                    </label>
                    <input
                        type="email"
                        className="form-control form-input"
                        id="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>

                <div className="col-6 form-control-container">
                    <label htmlFor="bankAccount" className="form-label form-control-label">
                        Bank Account
                    </label>
                    <input
                        type="text"
                        className="form-control form-input"
                        id="bankAccount"
                        value={bankAccount}
                        onChange={(event) => setBankAccount(event.target.value)}
                    />
                </div>

                <div className="col-12">
                    <label htmlFor="address" className="form-label form-control-label">
                        Address
                    </label>
                    <textarea
                        className="form-control form-input"
                        id="address"
                        rows="3"
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                    ></textarea>
                </div>

                <div className="d-flex justify-content-start mt-4 mb-3">
                    <button type="submit" className="btn btn-secondary me-2" onClick={() => onFormCancle()}>
                        Cancle
                    </button>  <button type="submit" className="btn btn-primary" onClick={(e) => onFormClick(e)}>
                        {edit ? 'Edit' : 'Save'}
                    </button>
                </div>

                {
                    showAlert &&
                    <div className="col-12 mt-4 mb-3">
                        <span htmlFor="name" className="validate-error">
                            {alertMessage}
                        </span>
                    </div>
                }

            </div>
        );
};

export default ClientForm;