import React, { useState, useEffect } from "react";
import useProjectList from '../../../hooks/Projects/useProjectList';
import Table from "../../../Common/table/Table";
import DeleteModal from "../../../Common/DeleteModal";
import AddProjectModal from "./add-Project-Modal";

const ProjectList = () => {
    const columns = [{ name: "Name", columnNumber: 0 }, { name: "Client", columnNumber: 1 }, { name: "Address", columnNumber: 2 }, { name: "Price Per Hour", columnNumber: 3 }];
    const { displayProjects, isLoading, showModal, message, showDeleteModal, deleteText, onSaveAndClose,
        onRefreshClick, onShowModalClick, onClosDeleteModal, onDeleteClick, onCloseModalClick, handleEdit, handleDelete } = useProjectList();

    if (isLoading) {
        return <div>Loading...</div>;
    } else {
        if (displayProjects) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <DeleteModal showModal={showDeleteModal} text={deleteText} title={"Client"} onCloseModalClick={onClosDeleteModal} onDeleteClick={onDeleteClick} />
                        <AddProjectModal showModal={showModal}
                            onSaveAndClose={onSaveAndClose}
                            onCloseModalClick={onCloseModalClick} />
                        {displayProjects.length > 0 ? (
                            <div className="col-12">
                                <h2>Project List</h2>

                                <Table
                                    title={"Projects"}
                                    onRefreshClick={onRefreshClick}
                                    message={message}
                                    onShowModalClick={onShowModalClick}
                                    columns={columns}
                                    data={displayProjects}
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                    showPanal={true} />
                            </div>
                        ) : (
                            <div>No Projects found.</div>
                        )}
                    </div></div>
            );
        }
    }
}

export default ProjectList;