import { useState } from "react";
import { AddClient } from '../../api/clientApi';
import { useUserContext } from "../useUserContext";
const useAddClient = () => {
    const { state, dispatch } = useUserContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [clearForm, setClearForm] = useState(false);
    const [isValid, setIsValid] = useState(false); // add isValid state variable


    const saveNewClient = async () => {
        const clientData = {


        };

        return await AddClient(clientData, state.token);
    }
    const intialClient = {
        name: "",
        tva: "",
        contactPerson: "",
        mobile: "",
        email: "",
        address: "",
        bankAccount: ""


    };
    const validateForm = () => { };
    return {
        showAlert, alertMessage, alertType, clearForm, intialClient,
        onFormClick: async (client) => {
            var response = await AddClient(client, state.token);
            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setShowAlert(true);
                setClearForm(true);
                setAlertMessage("Client saved successfully!");
                setAlertType("success");
                return true;

            } else {
                setShowAlert(true);
                setClearForm(false);
                setAlertMessage("Client is not saved");
                setAlertType("danger");
                return false;

            }
        },
        onCloseAlertClick: () => {
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
        }
    }


}
export default useAddClient;