
const DeleteModal = (props) => {
    return (
        <div className={` modal ${props.showModal ? 'show' : ''} `} tabindex="-1">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable deleteModal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">{`Are you sure want to delete ${props.title} ?`}</h2>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p> <strong>  </strong>{props.text.split('\n').map((line, index) => (
                            <span key={index}>{line}<br /></span>
                        ))}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props.onDeleteClick}>Delete</button>
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={props.onCloseModalClick}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DeleteModal;

