import axios from 'axios';
const Agent = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    const responseBody = (response) => response.data;
    const responsePostBody = (response) => {
        return response
    };
    const errorBody = (error) => (error.response);
    const requests = {

        getFile: (url, attribute, token) =>
            axios
                .get(url, {
                    params: attribute,
                    headers: {
                        Authorization: "Bearer " + token,
                        Accept: "application/json",

                    }, responseType: 'blob'
                })
                .then(responsePostBody),
        getAtt: (url, attribute, token) =>

            axios
                .get(url, {
                    params: attribute,
                    headers: {
                        Authorization: "Bearer " + token,
                        Accept: "application/json",
                    }
                })
                .then(responseBody),
        get: (url, token) =>
            axios
                .get(url, {
                    headers: {
                        Authorization: "Bearer " + token,
                        Accept: "application/json",
                    }
                })
                .then(responseBody),
        loginPost: (url, body) =>
            axios
                .post(url, body, {
                    headers: {
                        'Content-type': 'application/json',
                        Accept: "application/json",
                    }
                })
                .then(responseBody)
                .catch(errorBody),
        post: (url, body, token) =>
            axios
                .post(url, body, {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: "Bearer " + token,
                        Accept: "application/json",
                    }
                })
                .then(responsePostBody)
                .catch(errorBody),
        put: (url, body, token) =>
            axios
                .put(url, body, {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: "Bearer " + token,
                        Accept: "application/json",
                    }
                })
                .then(responsePostBody)
                .catch(errorBody),
        del: (url, token) =>
            axios
                .delete(url, {
                    headers: {
                        Authorization: "Bearer " + token,
                        Accept: "application/json",
                    }
                })
                .then(responsePostBody)
                .catch(errorBody),
        postForm: (url, file, token) => {
            let formData = new FormData();
            formData.append('File', file);
            return axios
                .post(url, file, {
                    headers: {
                        'Content-type': 'multipart/form-data',
                        Authorization: "Bearer " + token,
                        Accept: "application/json",
                    }
                })
                .then(responsePostBody).catch(errorBody);
        }
    };
    return {
        requests
    }

}
export default Agent;