import { useState, useEffect } from "react";
import { GetJobTitles } from '../../api/employeeApi';
import { useUserContext } from "../useUserContext";
import { useNavigate } from 'react-router-dom';
const useEmployeeForm = ({ employee, onFormSumbit, edit, isModal, onCloseModalClick }) => {
    const { state, dispatch } = useUserContext();
    const [showAlert, setShowAlert] = useState(false);
    const [jobTitles, setJobTitles] = useState([]);
    const [alertMessage, setAlertMessage] = useState("");
    const [intialEmployee, setEmployee] = useState({
        alias: employee.alias || '',
        firstName: employee.firstName || '',
        lastName: employee.lastName || '',
        address: employee.address || '',
        mobile: employee.mobile || '',
        email: employee.email || '',
        pricePerHour: employee.pricePerHour || 0,
        dateOfBirth: employee.dateOfBirth || '',
        startDate: employee.startDate || '',
        endDate: employee.endDate || null,
        nationality: employee.nationality || '',
        nationalNumber: employee.nationalNumber || '',
        nationalAddress: employee.nationalAddress || '',
        jobTitle: employee.jobTitle || '',
        bankAccount: employee.bankAccount || ''
        , familyContactPerson: employee.familyContactPerson || ''
        , mobileFCP: employee.MobileFCP || ''
        , bloodType: employee.BloodType || ''
        , diseasAllergies: employee.DiseasAllergies || ''
        , drivingLicense: employee.DrivingLicense || false
    });
    const [isValid, setIsValid] = useState(false); // add isValid state variable
    const history = useNavigate();
    console.log(employee);
    useEffect(() => {
        const fetchJobTitles = async () => {
            if (Object.keys(jobTitles).length === 0) {
                await fetchAllJobTitles();
            }
        }
        fetchJobTitles();

    }, []);
    const fetchAllJobTitles = async () => {
        var response = await GetJobTitles(state.token);
        let displayJobTitles = await response.map(item => ({
            id: item.id,
            name: item.name
        }));
        setJobTitles(displayJobTitles);
    }
    // add validation function to check if all the required fields are filled
    const validateForm = () => {

        if (intialEmployee.firstName &&
            intialEmployee.lastName &&
            intialEmployee.address &&
            intialEmployee.mobile &&
            intialEmployee.email &&
            intialEmployee.pricePerHour &&
            intialEmployee.dateOfBirth &&
            intialEmployee.startDate &&
            intialEmployee.nationality &&
            intialEmployee.nationalNumber &&
            intialEmployee.jobTitle) {
            return true;

        } else {

            return false;
        }
    };
    const clearForm = () => {
        setEmployee({
            firstName: '',
            lastName: '',
            address: '',
            mobile: '',
            email: '',
            pricePerHour: 0,
            dateOfBirth: '',
            startDate: '',
            endDate: null,
            nationality: '',
            nationalNumber: '',
            jobTitle: 0,
            nationalAddress: '',
            bankAccount: ''
            , familyContactPerson: ''
            , mobileFCP: ''
            , bloodType: ''
            , diseasAllergies: ''
            , drivingLicense: false
        });
    }
    return {
        intialEmployee, alertMessage, showAlert, jobTitles,
        onFormClick: async (event) => {
            event.preventDefault();
            const employeeData = {
                Alias: intialEmployee.alias,
                FirstName: intialEmployee.firstName,
                LastName: intialEmployee.lastName,
                Address: intialEmployee.address,
                Mobile: intialEmployee.mobile,
                Email: intialEmployee.email,
                PricePerHour: intialEmployee.pricePerHour,
                DateOfBirth: intialEmployee.dateOfBirth,
                StartDate: intialEmployee.startDate,
                Nationality: intialEmployee.nationality,
                NationalNumber: intialEmployee.nationalNumber,
                JobTitle: intialEmployee.jobTitle,
                NationalAddress: intialEmployee.nationalAddress,
                BankAccount: intialEmployee.bankAccount
                , FamilyContactPerson: intialEmployee.familyContactPerson
                , MobileFCP: intialEmployee.mobileFCP
                , BloodType: intialEmployee.bloodType
                , DiseasAllergies: intialEmployee.diseasAllergies
                , DrivingLicense: intialEmployee.drivingLicense
            };

            if (validateForm()) {
                if (edit) {
                    var success = await onFormSumbit(employeeData);
                    if (success) {
                        clearForm();
                        setTimeout(() => {
                            history('/all-clients');
                        }, 3000);
                    }
                } else {
                    var success = await onFormSumbit(employeeData);
                    if (success) {
                        clearForm();
                        if (isModal) {
                            onCloseModalClick();
                        }
                    }
                }
            }
            else {
                setShowAlert(true);
                setAlertMessage("Some input is not filled in");
            }
        },
        handleInputChange: (event) => {
            const { name, value } = event.target;
            setEmployee((prevEmployee) => ({
                ...prevEmployee,
                [name]: value,
            }));
            console.log(employee);
        },
        handleDateChange: (name, value) => {
            setEmployee((prevEmployee) => ({
                ...prevEmployee,
                [name]: value,
            }));
            console.log(employee);
        },

        onFormCancle: () => {
            clearForm();
            if (isModal) {
                onCloseModalClick();
            }
            if (edit) {
                history('/all-clients');
            }

        }

    }

}
export default useEmployeeForm;