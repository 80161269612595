import { useState, useEffect } from "react";
import { GetAllEmployee } from '../../api/employeeApi';
import { GetAlluserRoles } from '../../api/userApi';
import { useUserContext } from "../useUserContext";
import { useNavigate } from 'react-router-dom';
const useUserForm = ({ user, onFormSumbit, edit, isModal, onCloseModalClick }) => {

    const { state, dispatch } = useUserContext();
    const [selectedUserRole, setSelectedUserRole] = useState(user.selectedUserRole || "");
    const [email, setEmail] = useState(user.email || "");
    const [username, setUsername] = useState(user.username || "");
    const [selectedEmployee, setSelectedEmployee] = useState(user.SelectedEmployee || "");
    const [employees, setEmployees] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [isValid, setIsValid] = useState(false); // add isValid state variable
    const history = useNavigate();
    const clearForm = () => {
        setSelectedUserRole("");
        setEmail("");
        setUsername("");
        setSelectedEmployee("");
    }
    useEffect(() => {
        var employee = employees.find(x => x.id == selectedEmployee);
        if (employee != null) {
            setUsername(employee.name.split(" ").join(""));
            setEmail(employee.name.split(" ").join(""));
        }
    }, [selectedEmployee])
    useEffect(() => {
        const fetchEmployees = async () => {
            if (Object.keys(employees).length === 0) {
                await fetchAllEmployees();
            }
        }
        const fetchUserRoles = async () => {
            if (Object.keys(userRoles).length === 0) {
                await fetchAllUserRoles();
            }
        }
        fetchEmployees();
        fetchUserRoles();
    }, []);

    const fetchAllEmployees = async () => {
        var response = await GetAllEmployee(state.token);

        let displayEmployee = await response.map(item => ({
            id: item.id,
            name: item.firstName + ' ' + item.lastName
        }));
        setEmployees(displayEmployee);

    }
    const fetchAllUserRoles = async () => {
        var response = await GetAlluserRoles(state.token);
        let displayusrRole = await response.map(item => ({
            id: item.id,
            name: item.name
        }));
        setUserRoles(displayusrRole);
        setSelectedUserRole(edit ? user.selectedUserRole : displayusrRole[0].id);

    }
    // add validation function to check if all the required fields are filled
    const validateForm = () => {
        if (email && username && selectedEmployee && selectedUserRole) {
            return true;
        } else {
            return false;
        }
    };

    return {
        selectedUserRole, selectedEmployee, email, username, userRoles, employees, showAlert, alertMessage, setEmail, setUsername, setSelectedUserRole, setSelectedEmployee,
        onFormClick: async (event) => {
            event.preventDefault();
            const userData = {
                Email: email,
                EmployeeId: selectedEmployee,
                UserRole: selectedUserRole,
                UserName: username

            };
            if (validateForm()) {
                if (edit) {
                    var success = await onFormSumbit(userData);
                    if (success) {
                        clearForm();
                        setTimeout(() => {
                            history('/all-users');
                        }, 3000);

                    }
                } else {
                    var success = await onFormSumbit(userData);
                    if (success) {
                        clearForm();
                        if (isModal) {
                            onCloseModalClick();
                        }
                    }
                }
            }
            else {
                setShowAlert(true);
                setAlertMessage("Some input is not filled in");
            }
        }

        ,
        onFormCancle: () => {
            clearForm();
            if (isModal) {
                onCloseModalClick();
            }
            if (edit) {
                history('/all-users');
            }

        }
    }


}
export default useUserForm;