import React, { useState } from "react";
import useAddClient from '../../../hooks/clients/useAddClient';
import Alerts from "../../../Common/Alerts";
import ClientForm from "../client-form/client-form";
const AddClient = () => {

    const {
        showAlert, alertMessage, alertType, intialClient, onFormClick, onCloseAlertClick
    } = useAddClient();
    return (
        <div className="container">
            <div className="main-page-content">
                <h2 className="main-page-conten-title">Add Client</h2>

                <div className="row">
                    <Alerts show={showAlert} message={alertMessage} type={alertType} onCloseAlertClick={onCloseAlertClick} />

                </div>
                <ClientForm
                    client={intialClient}
                    onFormSumbit={onFormClick}
                    edit={false}
                    isModal={false} />

            </div>
        </div>

    );
};

export default AddClient;