import { useState } from "react";
import { AddProject } from '../../api/projectApi';
import { useUserContext } from "../useUserContext";
const useAddProject = () => {
    const { state, dispatch } = useUserContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [clearForm, setClearForm] = useState(false);
    const [isValid, setIsValid] = useState(false); // add isValid state variable

    const intialProject = {
        name: "",
        address: "",
        client: "",
        pricePerHour: 0,
        fixedPrice: true,
        employee: []
    };
    const validateForm = () => {

    };
    return {
        showAlert, alertMessage, alertType, clearForm, intialProject,
        onFormClick: async (project) => {
            var response = await AddProject(project, state.token);

            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setShowAlert(true);
                setClearForm(true);
                setAlertMessage("Project saved successfully!");
                setAlertType("success");
                return true;

            } else {
                setShowAlert(true);
                setClearForm(false);
                setAlertMessage("Project is not saved");
                setAlertType("danger");
                return false;

            }
        },
        onCloseAlertClick: () => {
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
        }
    }


}
export default useAddProject;