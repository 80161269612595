import React from "react";
import { NavLink } from "react-router-dom";
import useNavSideBar from "../hooks/useNavSideBar";
import './NavSideBar.css';
const NavSideBar = () => {
    const { activeItem, handleItemClick } = useNavSideBar();
    return (
        <nav className="nav flex-column">
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <div className="list-group-item">
                        <h2 className="accordion-header" onClick={() => handleItemClick(0)}>
                            <button className={`accordion-button  ${activeItem === 0 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className="sidebarIcon me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-monitor">
                                        <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line><line x1="12" y1="17" x2="12" y2="21"></line>
                                    </svg>
                                </span>
                                <span>
                                    Dashboard
                                </span>
                            </button>
                        </h2>

                    </div>
                </div>
                <div className="accordion-item">
                    <div className="list-group-item">
                        <h2 className="accordion-header" onClick={() => handleItemClick(1)}>
                            <button className={`accordion-button  ${activeItem === 1 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className="sidebarIcon me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-users">
                                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                    </svg>
                                </span>
                                <span>
                                    Employees
                                </span>
                            </button>
                        </h2>
                        <div id="collapseOne" className={`accordion-collapse collapse ${activeItem === 1 ? 'show' : ''}`}>

                            <div className="accordion-body list-group">
                                <div className="list-group-item">
                                    <NavLink to="employee-list">All Employee</NavLink ></div><div className="list-group-item">
                                    <NavLink to="/add-employee">Add Employee</NavLink ></div><div className="list-group-item">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" onClick={() => handleItemClick(2)}>
                        <button className={`accordion-button  ${activeItem === 2 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <span className="sidebarIcon me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle>
                                </svg> </span>
                            Client
                        </button>
                    </h2>
                    <div id="collapseTwo" className={`accordion-collapse collapse ${activeItem === 2 ? 'show' : ''}`} >
                        <div className="accordion-body list-group">
                            <div className="list-group-item">
                                <NavLink to="all-clients" activeClassName="active">All Clients</NavLink ></div><div className="list-group-item">
                                <NavLink to="add-client" activeClassName="active">Add Client</NavLink >
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" onClick={() => handleItemClick(3)}>
                        <button className={`accordion-button  ${activeItem === 3 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <span className="sidebarIcon me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-book">
                                    <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                                </svg> </span>

                            Project
                        </button>
                    </h2>
                    <div id="collapseTwo" className={`accordion-collapse collapse ${activeItem === 3 ? 'show' : ''}`} >
                        <div className="accordion-body list-group">
                            <div className="list-group-item">
                                <NavLink to="all-projects" activeClassName="active">All Project</NavLink ></div><div className="list-group-item">
                                <NavLink to="add-project" activeClassName="active">Add Projject</NavLink >
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">   <h2 className="accordion-header" onClick={() => handleItemClick(4)}>
                    <button className={`accordion-button  ${activeItem === 3 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span className="sidebarIcon me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-file-text">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline>
                            </svg> </span>

                        Reports
                    </button>
                </h2>
                    <div id="collapseTwo" className={`accordion-collapse collapse ${activeItem === 4 ? 'show' : ''}`} >
                        <div className="accordion-body list-group">
                            <div className="list-group-item">
                                <NavLink to="service-report" activeClassName="active">Service report</NavLink ></div><div className="list-group-item">
                                <NavLink to="employee-report" activeClassName="active">Employee report</NavLink ></div><div className="list-group-item">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" onClick={() => handleItemClick(5)}>
                        <button className={`accordion-button  ${activeItem === 4 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <span className="sidebarIcon me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-user-check">
                                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline>
                                </svg></span>
                            <span>User</span>
                        </button>
                    </h2>
                    <div id="collapseTwo" className={`accordion-collapse collapse ${activeItem === 5 ? 'show' : ''}`} >
                        <div className="accordion-body list-group">
                            <div className="list-group-item">
                                <NavLink to="/all-users" activeClassName="active">All Users</NavLink ></div><div className="list-group-item">
                                <NavLink to="add-user" activeClassName="active">Add User</NavLink >
                            </div>
                        </div>
                    </div>
                </div>


            </div>



        </nav >
    );
};
export default NavSideBar;
