import { useState } from "react";
import { AddUser } from '../../api/userApi';
import { useUserContext } from "../useUserContext";
const useAddUser = () => {
    const { state, dispatch } = useUserContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [isValid, setIsValid] = useState(false); // add isValid state variable


    const saveNewClient = async () => {
        const clientData = {


        };

        return await AddUser(clientData, state.token);
    }
    const initialUser = {
        email: "",
        selectedUserRole: "",
        SelectedEmployee: "",
        username: ""


    };
    const clearForm = () => {

    };
    const validateForm = () => { };
    return {
        showAlert, alertMessage, alertType, initialUser,
        onFormClick: async (user) => {
            console.log(user);
            var response = await AddUser(user, state.token);
            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setShowAlert(true);
                clearForm();
                setAlertMessage("Client saved successfully!");
                setAlertType("success");
                return true;

            } else {
                setShowAlert(true);
                setAlertMessage("Client is not saved");
                setAlertType("danger");
                return false;

            }
        },
        onCloseAlertClick: () => {
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
        }
    }


}
export default useAddUser;