import { useState } from "react";
import { AddEmployee } from '../../api/employeeApi';
import { useUserContext } from "../useUserContext";
const useAddEmployee = () => {
    const { state, dispatch } = useUserContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [clearForm, setClearForm] = useState(false);
    const [isValid, setIsValid] = useState(false); // add isValid state variable



    const initialEmployee = {
        alias: '',
        firstName: '',
        lastName: '',
        address: '',
        mobile: '',
        email: '',
        pricePerHour: 0,
        dateOfBirth: '',
        startDate: '',
        endDate: null,
        nationality: '',
        nationalNumber: '',
        nationaAddress: '',
        jobTitle: 0,
        bankAccount: ''
        , familyContactPerson: ''
        , mobileFCP: ''
        , bloodType: ''
        , diseasAllergies: ''
        , drivingLicense: false

    };
    const validateForm = () => { };
    return {
        showAlert, alertMessage, alertType, clearForm, initialEmployee,
        onFormClick: async (employee) => {
            var response = await AddEmployee(employee, state.token);
            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setShowAlert(true);
                setClearForm(true);
                setAlertMessage("Employee saved successfully!");
                setAlertType("success");
                return true;

            } else {
                setShowAlert(true);
                setClearForm(false);
                setAlertMessage("Employee is not saved");
                setAlertType("danger");
                return false;

            }
        },
        onCloseAlertClick: () => {
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
        }
    }


}
export default useAddEmployee;