import React from "react";
import { useState } from "react";
import EditIcon from '../../assets/icons/edit-icon.png';
import DeleteIcon from '../../assets/icons/delete-icon.png';
import plusIcon from '../../assets/icons/plus-icon.png';
import refreshIcon from '../../assets/icons/refresh-icon.png';

import './Table.css';
function Table({ columns, data, onEdit, message, onDelete, onRefreshClick, onShowModalClick, title }) {
    const [sortColumn, setSortColumn] = useState("");
    const [filterText, setFilterText] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");

    const handleSort = (column) => {
        if (column === sortColumn) {
            // Reverse the sort direction if the same column is clicked again
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            // Set the new sort column and default sort direction
            setSortColumn(column);
            setSortDirection("asc");
        }
    };
    const sortedData =
        [...data].filter(item => {
            return Object.values(item).some(value =>
                value.toString().toLowerCase().includes(filterText)
            );
        })
            .sort((a, b) => {
                if (sortDirection === "asc") {
                    return a[Object.keys(a)[sortColumn.columnNumber]] > b[Object.keys(b)[sortColumn.columnNumber]] ? 1 : -1;
                } else {
                    return a[Object.keys(a)[sortColumn.columnNumber]] < b[Object.keys(b)[sortColumn.columnNumber]] ? 1 : -1;
                }
            });
    const onInputChangehandle = (event) => {
        setFilterText(event.target.value.toLowerCase());
    }
    return (

        <div className="container-fluid ">
            <div className="row">
                <div className=" filter-panel d-flex align-items-center">
                    <div className="col-auto">
                        <h4 className="col-form-label">{title}</h4>
                    </div>
                    <div className="col-auto">
                        <input type="text" id="inputfilter" className="form-control search-input" value={filterText}
                            onChange={onInputChangehandle} />
                    </div>
                    <div className="col-auto">
                        <label className={`col-form alert ${message.length > 0 ? 'show' : ''}`}>{message}</label>
                    </div>

                    <div className="col-auto ms-auto">
                        <button className="btn btn-primary  me-3" onClick={onShowModalClick}>
                            <img src={plusIcon} alt="" />
                        </button>
                        <button className="btn btn-primary" onClick={onRefreshClick}>
                            <img src={refreshIcon} alt="" />
                        </button>

                    </div>
                </div>
            </div>
            <div className="row">

                <div className="scrollable-table-container">


                    <table className="table table-componenet">
                        <thead>
                            <tr>
                                {columns.map((column) => (
                                    <th className="table-header text-nowrap" key={column.name} onClick={() => handleSort(column)}>
                                        {column.name}
                                        {sortColumn === column.name && (
                                            <span className={`arrow ${sortDirection === "asc" ? "asc" : "desc"}`}></span>
                                        )}
                                    </th>
                                ))}
                                <th className="table-header " >Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedData.length === 0 ? (
                                    <div className="mb-3 ms-2">No Result</div>) : (
                                    sortedData.map((row, index) => (
                                        <tr key={index}>
                                            {columns.map((column) => (
                                                <td className="table-cell text-nowrap" key={column.name}>{row[Object.keys(sortedData[index])[column.columnNumber]]}</td>
                                            ))}
                                            <td className="table-cell text-nowrap" >
                                                <button className="btn btn-edit" onClick={() => onEdit(row)}>
                                                    <img src={EditIcon} />
                                                </button>
                                                <button className="btn btn-delete" onClick={() => onDelete(row)}>
                                                    <img src={DeleteIcon} />
                                                </button>
                                            </td>
                                        </tr>
                                    )))}
                        </tbody>
                    </table> </div>   </div>
        </div>
    );

}

export default Table;
