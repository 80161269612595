import { useState } from "react";
import { useUserContext } from "./useUserContext";
import { Login } from "../api/accountApi";
import { useNavigate } from "react-router-dom";
const useLogin = () => {
    const { state, dispatch } = useUserContext();
    //Kozmos_13456
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const history = useNavigate();
    const handleSubmit = async () => {
        var userInfo = await Login({ Username: username, Password: password });
        if (userInfo) {
            dispatch({
                type: 'LOGIN',
                user: userInfo.username || null,
                token: userInfo.token || null
            });
            history("/" + (state.redirect ? state.redirect : ""));
        }
    };
    return {
        username, password,
        onFormClick: async () => {
            handleSubmit();
        },
        onHandleKeyPress: (event) => {
            if (event.key === 'Enter') {
                handleSubmit();
            }
        },
        onUserChange: (e) => {
            e.preventDefault();
            setUsername(e.target.value);
        },
        onPasswordChange: (e) => {
            e.preventDefault();
            setPassword(e.target.value);
        },
        onClear: () => {
            setPassword('');
            setUsername('');
        },

    }
}


export default useLogin;