export const userReducer = (state, action) => {
  switch (action.type) {
    case 'AddItem':
      return {
        redirect: action.redirect
      }
    case 'LOGIN':
      return {
        user: action.user,
        token: action.token,

      };
    case 'LOGOUT':
      return {
        user: null,
        token: null,
      }
    case 'SEARCHPAGE':
      return {
        user: action.user || null,
        token: action.token || null,
      }


    default:
      throw new Error('Unexpected action');
  }
};
