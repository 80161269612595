import React from "react";
import Agent from "./agent.jsx";
export const AddClient = async (client, token) => {
    const { requests } = Agent();
    return await requests.post('/Client/AddClient', client, token);
}
export const GetAllClients = async (token) => {
    const { requests } = Agent();
    return await requests.get('/Client/GetAllClient', token);
}
export const EditClient = async (client, token) => {
    const { requests } = Agent();
    return await requests.put('/Client/EditClient', client, token);
}
export const GetClient = async (id, token) => {
    const { requests } = Agent();
    return await requests.get(`/Client/${id}`, token);
}
export const DeleteClient = async (id, token) => {
    const { requests } = Agent();
    return await requests.del(`/Client/${id}`, token);
}