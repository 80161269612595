import React, { Component } from "react";
import useEmployeeReport from '../../../hooks/service/useEmployeeReport';
import FilterTable from '../../../Common/filterTable/FilterTable';

const EmployeeReport = () => {


    const { clients, displayEmployees, columns, selectedEmployee, selectedClient, displayProjects, selectedProject, selectedFromDate, selectedToDate, serviceReport, totalHours,
        setSelectedEmployee, setSelectedClient, setSelectedProject, setSelectedFromDate, setSelectedToDate, onDownloadPdf } = useEmployeeReport();

    if (false) {
        return <div>Loading...</div>;
    } else {
        if (true) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h2>Employee Report</h2>
                            <FilterTable
                                title={"Filter"}
                                columns={columns}
                                combo1={{ name: 'Employee', visibility: true, data: displayEmployees }}
                                combo2={{ name: 'Client', visibility: true, data: clients }}
                                combo3={{ name: 'Project', visibility: true, data: displayProjects }}
                                data={serviceReport}
                                handleDate1Change={setSelectedFromDate}
                                selectedDate1={selectedFromDate}
                                selectedDate2={selectedToDate}
                                handleDate2Change={setSelectedToDate}
                                selectedCombo1={selectedEmployee}
                                selectedCombo2={selectedClient}
                                selectedCombo3={selectedProject}
                                handleCombo1Change={setSelectedEmployee}
                                handleCombo2Change={setSelectedClient}
                                handleCombo3Change={setSelectedProject}
                                onfirstButtonClick={onDownloadPdf}

                            />
                        </div>

                        <div>{totalHours > 0 ? `Total Hours:${totalHours}` : ''}</div>

                    </div>
                </div>
            );
        }
    }
}
export default EmployeeReport;