import React from "react";
import Agent from "./agent.jsx";
export const AddEmployee = async (employee, token) => {
    const { requests } = Agent();
    return await requests.post('/Employee/AddEmployee', employee, token);
}

export const GetJobTitles = async (token) => {
    const { requests } = Agent();
    return await requests.get('/JobTitle/GetJobTitles', token);
}
export const GetAllEmployee = async (token) => {
    const { requests } = Agent();
    return await requests.get('/Employee/GetAllEmployee', token);
}
export const EditEmployee = async (employee, token) => {
    const { requests } = Agent();
    return await requests.put('/Employee/EditEmployee', employee, token);
}
export const GetEmployee = async (id, token) => {
    const { requests } = Agent();
    return await requests.get(`/Employee/${id}`, token);
}
export const DeleteEmployee = async (id, token) => {
    const { requests } = Agent();
    return await requests.put(`/Employee/${id}`, token);
}