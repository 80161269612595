import { useRef, useEffect, useState } from "react";

import { useUserContext } from "./useUserContext";
const useNavSideBar = () => {
    const { state, dispatch } = useUserContext();
    const [activeItem, setActiveItem] = useState(null);

    return {
        activeItem,
        handleItemClick: (index) => {
            setActiveItem(index === activeItem ? null : index);
        }
    }
}
export default useNavSideBar;