import { useState, useEffect } from "react";
import { EditUser, GetUser } from '../../api/userApi';
import { useUserContext } from "../useUserContext";
import { useParams } from 'react-router-dom';
const useEditUser = () => {
    const { state, dispatch } = useUserContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [clearForm, setClearForm] = useState(false);
    const [user, setUser] = useState({});
    const [editUser, setEditUser] = useState({});

    const [isValid, setIsValid] = useState(false); // add isValid state variable
    const { id } = useParams();


    const validateForm = () => { };
    const getUserDetails = async (id) => {
        let usertData = await GetUser(id, state.token);
        console.log(usertData);
        const editUser = {
            email: usertData.email,
            username: usertData.username,
            selectedUserRole: usertData.userRole,
            SelectedEmployee: usertData.employeeId,

        };
        setUser(usertData);
        setEditUser(editUser);

    }
    useEffect(() => {
        const fetchUser = async () => {
            if (Object.keys(user).length === 0) {
                await getUserDetails(id);
            }
        }
        fetchUser();

    }, [id]);
    return {
        showAlert, alertMessage, alertType, clearForm, editUser,
        onFormClick: async (user) => {
            user['Id'] = id;
            console.log(user);
            var response = await EditUser(user, state.token);
            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setShowAlert(true);
                setClearForm(true);
                setAlertMessage("User saved successfully!");
                setAlertType("success");
                return true;



            } else {
                setShowAlert(true);
                setClearForm(false);
                setAlertMessage("User is not saved");
                setAlertType("danger");
                return false;

            }

        },
        onCloseAlertClick: () => {
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
        }
    }



}
export default useEditUser;