import React from "react";
import Agent from "./agent.jsx";
export const AddProject = async (project, token) => {
    const { requests } = Agent();
    return await requests.post('/Project/AddProject', project, token);
}
export const GetAllProjects = async (token) => {
    const { requests } = Agent();

    return await requests.get('/Project/GetAllProjects', token);
}
export const DeleteProject = async (id, token) => {
    const { requests } = Agent();
    return await requests.del(`/Project/${id}`, token);
}
export const EditProject = async (project, token) => {
    const { requests } = Agent();
    return await requests.put('/Project/EditProject', project, token);
}
export const GetProject = async (id, token) => {
    const { requests } = Agent();
    return await requests.get(`/Project/${id}`, token);
}
