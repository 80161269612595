import React, { useState } from "react";
import EmployeeForm from '../employee-form/employee-form';
import useAddEmployee from '../../../hooks/employees/useAddEmployee';
import Alerts from "../../../Common/Alerts";
const AddEmployee = () => {

    const { initialEmployee, showAlert, alertMessage, alertType, onFormClick, onCloseAlertClick } = useAddEmployee()
    return (
        <div className="container">
            <div className="main-page-content">
                <h2 className="main-page-conten-title">Add Employee</h2>

                <div className="row">
                    <Alerts show={showAlert} message={alertMessage} type={alertType} onCloseAlertClick={onCloseAlertClick} />
                </div>
                <EmployeeForm employee={initialEmployee}
                    onFormSumbit={onFormClick}
                    edit={false}
                    isModal={false} />

            </div>
        </div>

    )
}
export default AddEmployee