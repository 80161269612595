import { useState, useEffect } from "react";
import { GetAllEmployee, DeleteEmployee } from '../../api/employeeApi';
import { useUserContext } from "../useUserContext";
import { useNavigate } from 'react-router-dom';


const useEmployeeList = () => {
    const [employees, setEmployees] = useState([]);
    const [displayEmployees, setDisplayEmployees] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteText, setDeleteText] = useState('');
    const [message, setMessage] = useState('');
    const [idForDelete, setIdForDelete] = useState('');

    const [error, setError] = useState(null);
    const { state, dispatch } = useUserContext();
    const history = useNavigate();

    useEffect(() => {
        const fetchEmployees = async () => {
            if (Object.keys(employees).length === 0) {
                await fetchAllEmployees();
            }
        }
        fetchEmployees();
    }, []);

    const fetchAllEmployees = async () => {
        var response = await GetAllEmployee(state.token);
        let displayEmployees = await response.map(item => ({
            alias: item.alias,
            firstName: item.firstName,
            lastName: item.lastName,
            dateOfBirth: item.dateOfBirth,
            address: item.address,
            mobile: item.mobile,
            email: item.email,
            nationality: item.nationality,
            nationalNumber: item.nationalNumber,
            nationalAddress: item.nationalAddress,
            jobTitle: item.jobTitle,
            pricePerHour: item.pricePerHour,
            startDate: item.startDate,
            endDate: item.EndDate,
            bankAccount: item.bankAccount
            , familyContactPerson: item.familyContactPerson
            , mobileFCP: item.mobileFCP
            , bloodType: item.bloodType
            , diseasAllergies: item.diseasAllergies
            , drivingLicense: item.drivingLicense ? 'YES' : 'NO'
        }));
        setDisplayEmployees(displayEmployees);
        setEmployees(response);
        setIsLoading(false);
        setError(null);
    }

    return {
        isLoading,
        displayEmployees,
        showModal,
        message,
        showDeleteModal,
        deleteText,
        onRefreshClick: () => {
            fetchAllEmployees();
        },
        onSaveAndClose: (message) => {
            setShowModal(false);
            setMessage(message);
            fetchAllEmployees();
            setTimeout(() => {
                setMessage('');
            }, 4000);
        },
        onShowModalClick: () => {
            setShowModal(true);
        },
        onCloseModalClick: () => {
            setShowModal(false);
        },
        handleEdit: (employee) => {
            let id = employees.find(x => x.firstName == employee.firstName && x.lastName == employee.lastName).id;
            history(`/edit-employee/${id}`);
        },
        handleDelete: (employee) => {
            let id = employees.find(x => x.firstName == employee.firstName && x.lastName == employee.lastName).id;
            setShowDeleteModal(true);
            setIdForDelete(id);
            setDeleteText(" First name:" + employee.firstName + "\n"
                + " Last Name: " + employee.lastName + "\n");
        },
        onClosDeleteModal: () => {
            setDeleteText('');
            setShowDeleteModal(false);
        },
        onDeleteClick: async () => {
            var response = await DeleteEmployee(idForDelete, state.token);

            if (response.status === 200 || response.status === 201) {
                // Do something if successful
                setDeleteText('');
                setShowDeleteModal(false);
                setMessage("Employee is deleted successfully!");
                fetchAllEmployees();
                setTimeout(() => {
                    setMessage('');
                }, 4000);

            } else {
                setDeleteText(" Employee is not deleted!!! ");
            }

        }
    }


}
export default useEmployeeList;