import React from "react";
import Table from "../../../Common/table/Table";
import useEmployeeList from "../../../hooks/employees/useEmployeeList";
import DeleteModal from "../../../Common/DeleteModal";
import AddEmployeeModal from "./add-employe-Modal";
const EmployeeList = () => {

    const columns = [
        { name: "Alias", columnNumber: 0 }
        , { name: "First Name", columnNumber: 1 }
        , { name: "Last Name", columnNumber: 2 }
        , { name: "Date Of Birth", columnNumber: 3 }
        , { name: "Address", columnNumber: 4 }
        , { name: "Mobile", columnNumber: 5 }
        , { name: "Email", columnNumber: 6 }
        , { name: "Nationality", columnNumber: 7 }
        , { name: "National Number", columnNumber: 8 }
        , { name: "National Address", columnNumber: 9 }
        , { name: "Job Title", columnNumber: 10 }
        , { name: "Price per Hour", columnNumber: 11 }
        , { name: "Start Date", columnNumber: 12 }
        , { name: "End Date", columnNumber: 13 }
        , { name: "bankAccount", columnNumber: 14 }
        , { name: "Family Contact Person", columnNumber: 15 }
        , { name: "MobileFCP", columnNumber: 16 }
        , { name: "Blood Type", columnNumber: 17 }
        , { name: "Diseas or Allergies", columnNumber: 18 }
        , { name: "Driving License", columnNumber: 19 }

    ];
    const { displayEmployees, isLoading, showModal, message, showDeleteModal, deleteText, onSaveAndClose,
        onRefreshClick, onShowModalClick, onClosDeleteModal, onDeleteClick, onCloseModalClick, handleEdit, handleDelete } = useEmployeeList();


    if (isLoading) {
        return <div>Loading...</div>;
    } else {
        if (displayEmployees) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <DeleteModal showModal={showDeleteModal} text={deleteText} title={"Employee"} onCloseModalClick={onClosDeleteModal} onDeleteClick={onDeleteClick} />
                        <AddEmployeeModal
                            showModal={showModal}
                            onSaveAndClose={onSaveAndClose}
                            onCloseModalClick={onCloseModalClick}

                        />
                        {displayEmployees.length > 0 ? (
                            <div className="col-12">
                                <h2>Employee List</h2>

                                <Table
                                    onRefreshClick={onRefreshClick}
                                    message={message}
                                    onShowModalClick={onShowModalClick}
                                    columns={columns}
                                    data={displayEmployees}
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                />
                            </div>
                        ) : (
                            <div>No clients found.</div>
                        )}
                    </div></div>
            );


        }
    }
}
export default EmployeeList;