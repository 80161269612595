import { useState, useEffect } from "react";
import { AddClient } from '../../api/clientApi';
import { useUserContext } from "../useUserContext";
import { useNavigate } from 'react-router-dom';
const useClientForm = ({ client, onFormSumbit, edit, isModal, onCloseModalClick }) => {

    const { state, dispatch } = useUserContext();
    const [name, setName] = useState(client.name || "");
    const [tva, setTva] = useState(client.tva || "");
    const [contactPerson, setContactPerson] = useState(client.contactPerson || "");
    const [mobile, setMobile] = useState(client.mobile || "");
    const [email, setEmail] = useState(client.email || "");
    const [address, setAddress] = useState(client.address || "");
    const [bankAccount, setBankAccount] = useState(client.bankAccount || "");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [isValid, setIsValid] = useState(false); // add isValid state variable
    const history = useNavigate();
    const clearForm = () => {
        setTva("");
        setName("");
        setContactPerson("");
        setMobile("");
        setEmail("");
        setAddress("");
        setBankAccount("");
    }

    // add validation function to check if all the required fields are filled
    const validateForm = () => {
        if (name && tva && mobile && email) {
            return true;
        } else {
            return false;
        }
    };

    return {
        bankAccount, address, email, mobile, contactPerson, name, tva, showAlert, alertMessage, setName, setTva, setContactPerson, setMobile, setEmail, setAddress, setBankAccount,
        onFormClick: async (event) => {
            event.preventDefault();
            const clientData = {
                Name: name,
                TVA: tva,
                ContactPerson: contactPerson,
                Mobile: mobile,
                Email: email,
                Address: address,
                BankAccount: bankAccount

            };
            if (validateForm()) {
                if (edit) {
                    var success = await onFormSumbit(clientData);
                    if (success) {
                        clearForm();
                        setTimeout(() => {
                            history('/all-clients');
                        }, 3000);

                    }
                } else {
                    var success = await onFormSumbit(clientData);
                    if (success) {
                        clearForm();
                        if (isModal) {
                            onCloseModalClick();
                        }
                    }
                }
            }
            else {
                setShowAlert(true);
                setAlertMessage("Some input is not filled in");
            }
        }

        ,
        onFormCancle: () => {
            clearForm();
            if (isModal) {
                onCloseModalClick();
            }
            if (edit) {
                history('/all-clients');
            }

        }
    }


}
export default useClientForm;