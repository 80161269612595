import React, { Component } from "react";
import useAddProject from '../../../hooks/Projects/useAddProject';
import ProjectForm from "../project-form/project-form";
const AddProjectModal = ({ showModal, onCloseModalClick, onSaveAndClose }) => {

    const {
        showAlert, intialProject,
        alertMessage, alertType, onCloseAlertClick, onFormClick
    } = useAddProject();
    if (showAlert && alertType === "success") {
        onSaveAndClose(alertMessage);
        onCloseAlertClick();
    }
    return (
        <div className={`modal  ${showModal ? 'show' : ''}`} tabindex="-1">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">New Project</h5>
                    </div>
                    <div className="modal-body">
                        <ProjectForm
                            project={intialProject}
                            onFormSumbit={onFormClick}
                            edit={false}
                            isModal={true}
                            onCloseModalClick={onCloseModalClick} />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AddProjectModal;