import React, { useState, useEffect } from "react";
import useUserList from '../../../hooks/users/useUserList';
import Table from "../../../Common/table/Table";
import DeleteModal from "../../../Common/DeleteModal";
import AddUserModal from './add-user-modal';
const UserList = () => {
    const columns = [{ name: "User", columnNumber: 0 }, { name: "Employee", columnNumber: 1 }, { name: "User Role", columnNumber: 2 }];
    const { displayUsers, isLoading, showModal, message, showDeleteModal, deleteText, onSaveAndClose,
        onRefreshClick, onShowModalClick, onClosDeleteModal, onDeleteClick, onCloseModalClick, handleEdit, handleDelete } = useUserList();

    if (isLoading) {
        return <div>Loading...</div>;
    } else {
        if (displayUsers) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <AddUserModal showModal={showModal}
                            onSaveAndClose={onSaveAndClose}
                            onCloseModalClick={onCloseModalClick} />
                        <DeleteModal showModal={showDeleteModal} text={deleteText} title={"User"} onCloseModalClick={onClosDeleteModal} onDeleteClick={onDeleteClick} />

                        {displayUsers.length > 0 ? (
                            <div className="col-12">
                                <h1>User List</h1>

                                <Table
                                    title={"Users"}
                                    onRefreshClick={onRefreshClick}
                                    message={message}
                                    onShowModalClick={onShowModalClick}
                                    columns={columns}
                                    data={displayUsers}
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                />
                            </div>
                        ) : (
                            <div>No Users found.</div>
                        )}
                    </div></div>
            );
        }
    }
}

export default UserList;