import React, { useState } from "react";
import useProjectForm from '../../../hooks/Projects/useProjectForm';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import DatePicker from "react-widgets/DatePicker";
import useEmployeeForm from '../../../hooks/employees/useEmployeeForm';
const EmployeeForm = ({ employee, onFormSumbit, edit, isModal, onCloseModalClick }) => {

    const { intialEmployee, alertMessage, showAlert, jobTitles, onFormClick, onFormCancle, handleInputChange, handleDateChange } = useEmployeeForm({ employee, onFormSumbit, edit, isModal, onCloseModalClick });

    if (intialEmployee !== null)
        return (
            <div className="row client-form">

                <div className="col-6 form-control-container">
                    <label htmlFor="name" className="form-label form-control-label">
                        First Name
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.firstName}
                        onChange={handleInputChange}
                        name="firstName"
                    />
                </div>

                <div className="col-6 form-control-container">
                    <label htmlFor="name" className="form-label form-control-label">
                        Last Name
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.lastName}
                        onChange={handleInputChange}
                        name="lastName"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Alias
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.alias}
                        onChange={handleInputChange}
                        name="alias"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label htmlFor="" className="form-label form-control-label">
                        DateOfBirth
                    </label>
                    <DatePicker
                        containerClassName={" form-combobox"}
                        defaultValue={new Date()}
                        valueEditFormat={{ dateStyle: "short" }}
                        valueDisplayFormat={{ dateStyle: "medium" }}
                        onChange={value => handleDateChange("dateOfBirth", value)}
                    />

                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Mobile
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.mobile}
                        onChange={handleInputChange}
                        name="mobile"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Email
                    </label>
                    <input
                        className="form-control form-input"
                        type="email"
                        value={intialEmployee.email}
                        onChange={handleInputChange}
                        name="email"
                    />
                </div>


                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Price Per Hour
                    </label>
                    <input
                        className="form-control form-input"
                        type="number"
                        value={intialEmployee.pricePerHour}
                        onChange={handleInputChange}
                        name="pricePerHour"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Address
                    </label>

                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.address}
                        onChange={handleInputChange}
                        name="address"
                    />
                </div>

                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Nationality
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.nationality}
                        onChange={handleInputChange}
                        name="nationality"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        National Number
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.nationalNumber}
                        onChange={handleInputChange}
                        name="nationalNumber"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        National Address
                    </label>

                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.NationalAddress}
                        onChange={handleInputChange}
                        name="NationalAddress"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label htmlFor="" className="form-label form-control-label">
                        Job Title
                    </label>
                    <Combobox
                        onChange={value => handleDateChange("jobTitle", value.id)}
                        dataKey='id'
                        textField='name'
                        data={jobTitles}
                        value={intialEmployee.jobTitle}
                        defaultValue={intialEmployee.jobTitle}
                        containerClassName={" form-combobox"}
                    />

                </div>

                <div className="col-6 form-control-container ">
                    <label htmlFor="" className="form-label form-control-label">
                        Start Date
                    </label>
                    <DatePicker
                        containerClassName={" form-combobox"}
                        defaultValue={new Date()}
                        valueEditFormat={{ dateStyle: "short" }}
                        valueDisplayFormat={{ dateStyle: "medium" }}
                        onChange={value => handleDateChange("startDate", value)}
                    />

                </div>
                {edit && (<div className="col-6 form-control-container ">
                    <label htmlFor="" className="form-label form-control-label">
                        End Date
                    </label>
                    <DatePicker
                        containerClassName={" form-combobox"}
                        defaultValue={new Date()}
                        valueEditFormat={{ dateStyle: "short" }}
                        valueDisplayFormat={{ dateStyle: "medium" }}
                        onChange={value => handleDateChange("EndDate", value)}
                    />

                </div>)}
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Bank account
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.bankAccount}
                        onChange={handleInputChange}
                        name="bankAccount"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Family Contact Person
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.familyContactPerson}
                        onChange={handleInputChange}
                        name="familyContactPerson"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        MobileFCP
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.mobileFCP}
                        onChange={handleInputChange}
                        name="mobileFCP"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Blood Type
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.bloodType}
                        onChange={handleInputChange}
                        name="bloodType"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Diseas or Allergies
                    </label>
                    <input
                        className="form-control form-input"
                        type="text"
                        value={intialEmployee.diseasAllergies}
                        onChange={handleInputChange}
                        name="diseasAllergies"
                    />
                </div>
                <div className="col-6 form-control-container ">
                    <label className="form-label form-control-label">
                        Driving License
                    </label>

                    <Combobox
                        onChange={item => handleDateChange("drivingLicense", item.id)}
                        dataKey='id'
                        textField='value'
                        data={[{ id: true, value: 'YES' }, { id: false, value: 'NO' }]}
                        value={intialEmployee.drivingLicense}
                        defaultValue={intialEmployee.drivingLicense}
                        containerClassName={" form-combobox"}
                    />
                </div>

                <div className="d-flex justify-content-start mt-4 mb-3">
                    <button type="submit" className="btn btn-secondary me-2" onClick={() => onFormCancle()}>
                        Cancle
                    </button>  <button type="submit" className="btn btn-primary" onClick={(e) => onFormClick(e)}>
                        {edit ? 'Edit' : 'Save'}
                    </button>
                </div>

                {
                    showAlert &&
                    <div className="col-12 mt-4 mb-3">
                        <span htmlFor="name" className="validate-error">
                            {alertMessage}
                        </span>
                    </div>
                }

            </div>
        );
};

export default EmployeeForm;