import { useState, useEffect } from "react";
import { useUserContext } from "../useUserContext";
import { useNavigate } from 'react-router-dom';
import { GetAllClients, DeleteClient } from '../../api/clientApi';
import { GetAllEmployee } from '../../api/employeeApi';

const useProjectForm = ({ project, onFormSumbit, edit, isModal, onCloseModalClick }) => {
    console.log(project);
    const { state, dispatch } = useUserContext();
    const [name, setName] = useState(project.name || "");
    const [selectedClient, setSelectedClient] = useState('');
    const [fixedPrice, setFixedPrice] = useState(project.fixedPrice);
    const [address, setAddress] = useState(project.address || "");
    const [pricePerHour, setPricePerHour] = useState(project.pricePerHour || 0);
    const [clients, setClients] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [employees, setEmployees] = useState(project.employees || []);
    const [emplyeeHourPriceList, setEmplyeeHourPriceList] = useState([]);
    const [selectedSalary, setSelectedSalary] = useState();
    const [isValid, setIsValid] = useState(false); // add isValid state variable
    const history = useNavigate();
    const clearForm = () => {
        setSelectedClient(clients[0].id);
        setName("");
        setAddress("");
        setFixedPrice(true);
        setPricePerHour(0);
    }

    useEffect(() => {
        const fetchClients = async () => {
            if (Object.keys(clients).length === 0) {
                await fetchAllClients();
            }
        }
        fetchClients();
        const fetchEmployees = async () => {
            if (Object.keys(employees).length === 0) {
                await fetchAllEmployees();
            }
        }
        fetchClients();
        fetchEmployees();
    }, []);


    const fetchAllClients = async () => {
        var response = await GetAllClients(state.token);
        let displayClients = await response.map(item => ({
            id: item.id,
            name: item.name
        }));
        setClients(displayClients);
        setSelectedClient(edit ? project.client.toLowerCase() : displayClients[0].id);

    }
    const fetchAllEmployees = async () => {
        var response = await GetAllEmployee(state.token);
        let displayEmployees;
        if (edit) {
            displayEmployees = await response.map(itemA => {
                let matchingItemB = project.employees.find(itemB => itemB.id === itemA.id);
                if (matchingItemB) {
                    // If itemA.id exists in listB, return itemA with its corresponding b value from listB
                    return {
                        id: itemA.id,
                        name: itemA.firstName + ' ' + itemA.lastName,
                        pricePerHour: matchingItemB.pricePerHour
                    };
                } else {
                    // If itemA.id does not exist in listB, return itemA with b = 0
                    return {
                        id: itemA.id,
                        name: itemA.firstName + ' ' + itemA.lastName,
                        pricePerHour: 0
                    };
                }
            })
        } else {

            displayEmployees = await response.map(item => ({
                id: item.id,
                name: item.firstName + ' ' + item.lastName,
                pricePerHour: 0
            }));
        }
        setEmployees(displayEmployees);
    }
    // add validation function to check if all the required fields are filled
    const validateForm = () => {
        console.log(fixedPrice);
        if (name && selectedClient && address && (fixedPrice && pricePerHour > 0) || !fixedPrice) {
            return true;
        } else {
            return false;
        }
    };
    return {
        address, name, selectedClient, clients, showAlert, alertMessage, pricePerHour, fixedPrice, employees,
        setFixedPrice, setPricePerHour, setSelectedClient, setName, setAddress,
        onFormClick: async (event) => {
            event.preventDefault();
            const ProjectData = {
                Name: name,
                Client: selectedClient,
                Address: address,
                PricePerHour: pricePerHour,
                FixedPrice: fixedPrice,
                EmployeeSalaryPerProject: employees

            };
            if (validateForm()) {
                if (edit) {

                    var success = await onFormSumbit(ProjectData);

                    if (success) {
                        clearForm();
                        setTimeout(() => {
                            history('/all-projects');
                        }, 3000);

                    }
                } else {
                    var success = await onFormSumbit(ProjectData);
                    if (success) {
                        clearForm();
                        if (isModal) {
                            onCloseModalClick();
                        }
                    }
                }
            }
            else {
                setShowAlert(true);
                setAlertMessage("Some input is not filled in");
            }
        },
        updatePricePerHour: (value, index) => {
            const updateEmployees = employees.map((item, idx) =>
                idx === index ? { ...item, pricePerHour: isNaN(value) ? 0 : value } : item
            );
            console.log(updateEmployees);
            setEmployees(updateEmployees);
        },
        onFormCancle: () => {
            clearForm();
            if (isModal) {
                onCloseModalClick();
            }
            if (edit) {
                history('/all-clients');
            }

        }
    }


}
export default useProjectForm;